import React from 'react';
import './ReservationTrip.scss';
import StepOne from './StepOne.js';
import StepTwo from './StepTwo.js';
import StepThree from './StepThree.js';
import StepFour from './StepFour.js';
import StepFive from './StepFive.js';
import StepSix from './StepSix.js';
import StepSubmit from './StepSubmit.js';
import ReservationMenu from './ReservationMenu.js';
import StepsNumber from './StepsNumber.js';
import Login from './userRegistration/Login.js';
import regulamin from './userRegistration/documents/regulamin-taksidi.pdf';
import { Redirect } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import APIbackend from './config.js';

class ReservationTrip extends React.Component {
  state = {
    secondNewArray: [],
    addonsSelectedArray: [],
    validatePhone: true,
    messageSizeAddon: '',
    messageStepTwoJourneyPointId: '',
    messageStepTwoJourneyPointId2: '',
    colorJourneyPoinId: false,
    buttonAvailableStepOne: true,
    buttonAvailableStepTwo: true,
    buttonAvailableStepThree: true,
    buttonAvailableStepFour: true,
    buttonAvailableStepFive: true,
    buttonAvailableStepSix: true,
    messageAmbassadorVisible: false,
    codeAmbassadorFormVisible: true,
    buttonGenerateCodeVisible: true,
    onlinePayment: '',
    accommodationConfigNew: [],
    messageStepTwoCabin: '',
    messageKR: '',
    redirectToPayU: false,
    paymentLink: '',
    newAccConfig: [],
    roomId: '',
    journeyId: '',
    journeyPointId: '',
    buttonGrey: false,
    messageStepTwoJourney: '',
    colorRoom: '',
    colorJourney: '',
    messageStepTwoRoom: '',
    messageStepTwoJourney2: '',
    messageStepTwoRoom2: '',
    participantsCountWhole: '',
    participantsCountCabin: 2,
    skipper: 'NIE',
    beSkipper: 'NIE',
    countryList: [
      'Afghanistan',
      'Albania',
      'Algeria',
      'Andorra',
      'Angola',
      'Anguilla',
      'Antigua & Barbuda',
      'Argentina',
      'Armenia',
      'Aruba',
      'Australia',
      'Austria',
      'Azerbaijan',
      'Bahamas',
      'Bahrain',
      'Bangladesh',
      'Barbados',
      'Belarus',
      'Belgium',
      'Belize',
      'Benin',
      'Bermuda',
      'Bhutan',
      'Bolivia',
      'Bosnia &amp; Herzegovina',
      'Botswana',
      'Brazil',
      'British Virgin Islands',
      'Brunei',
      'Bulgaria',
      'Burkina Faso',
      'Burundi',
      'Cambodia',
      'Cameroon',
      'Cape Verde',
      'Cayman Islands',
      'Chad',
      'Chile',
      'China',
      'Colombia',
      'Congo',
      'Cook Islands',
      'Costa Rica',
      'Cote D Ivoire',
      'Croatia',
      'Cruise Ship',
      'Cuba',
      'Cyprus',
      'Czech Republic',
      'Denmark',
      'Djibouti',
      'Dominica',
      'Dominican Republic',
      'Ecuador',
      'Egypt',
      'El Salvador',
      'Equatorial Guinea',
      'Estonia',
      'Ethiopia',
      'Falkland Islands',
      'Faroe Islands',
      'Fiji',
      'Finland',
      'France',
      'French Polynesia',
      'French West Indies',
      'Gabon',
      'Gambia',
      'Georgia',
      'Germany',
      'Ghana',
      'Gibraltar',
      'Greece',
      'Greenland',
      'Grenada',
      'Guam',
      'Guatemala',
      'Guernsey',
      'Guinea',
      'Guinea Bissau',
      'Guyana',
      'Haiti',
      'Honduras',
      'Hong Kong',
      'Hungary',
      'Iceland',
      'India',
      'Indonesia',
      'Iran',
      'Iraq',
      'Ireland',
      'Isle of Man',
      'Israel',
      'Italy',
      'Jamaica',
      'Japan',
      'Jersey',
      'Jordan',
      'Kazakhstan',
      'Kenya',
      'Kuwait',
      'Kyrgyz Republic',
      'Laos',
      'Latvia',
      'Lebanon',
      'Lesotho',
      'Liberia',
      'Libya',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'Macau',
      'Macedonia',
      'Madagascar',
      'Malawi',
      'Malaysia',
      'Maldives',
      'Mali',
      'Malta',
      'Mauritania',
      'Mauritius',
      'Mexico',
      'Moldova',
      'Monaco',
      'Mongolia',
      'Montenegro',
      'Montserrat',
      'Morocco',
      'Mozambique',
      'Namibia',
      'Nepal',
      'Netherlands',
      'Netherlands Antilles',
      'New Caledonia',
      'New Zealand',
      'Nicaragua',
      'Niger',
      'Nigeria',
      'Norway',
      'Oman',
      'Pakistan',
      'Palestine',
      'Panama',
      'Papua New Guinea',
      'Paraguay',
      'Peru',
      'Philippines',
      'Portugal',
      'Puerto Rico',
      'Qatar',
      'Reunion',
      'Romania',
      'Russia',
      'Rwanda',
      'Saint Pierre &amp; Miquelon',
      'Samoa',
      'San Marino',
      'Satellite',
      'Saudi Arabia',
      'Senegal',
      'Serbia',
      'Seychelles',
      'Sierra Leone',
      'Singapore',
      'Slovakia',
      'Slovenia',
      'South Africa',
      'South Korea',
      'Spain',
      'Sri Lanka',
      'St Kitts &amp; Nevis',
      'St Lucia',
      'St Vincent',
      'St. Lucia',
      'Sudan',
      'Suriname',
      'Swaziland',
      'Sweden',
      'Switzerland',
      'Syria',
      'Taiwan',
      'Tajikistan',
      'Tanzania',
      'Thailand',
      "Timor L'Este",
      'Togo',
      'Tonga',
      'Trinidad &amp; Tobago',
      'Tunisia',
      'Turkey',
      'Turkmenistan',
      'Turks &amp; Caicos',
      'Uganda',
      'Ukraine',
      'United Arab Emirates',
      'United Kingdom',
      'Uruguay',
      'Uzbekistan',
      'Venezuela',
      'Vietnam',
      'Virgin Islands (US)',
      'Yemen',
      'Zambia',
      'Zimbabwe',
    ],
    colorInputRedAgree: '',
    colorMessageAmb: '',
    messageAmbassador: '',
    login: false,
    buttons: true,
    stepsNumber: true,
    messageStepSix: '',
    colorInputRedPhone: '',
    colorInputRedEmail: '',
    colorInputRedLastName: '',
    colorInputRedBirthDate: '',
    colorInputRedCountry: '',
    colorInputRedFirstName: '',
    colorInputRedGender: '',
    colorInputRedIdDocument: '',
    colorInputRedPassword: '',
    colorInputRedPolicyAcc: '',
    addGrupCodeVisible: true,

    voucherCode1: '',
    voucherCode2: '',
    voucherCode3: '',
    voucherCode4: '',
    voucherCode5: '',
    voucherCode6: '',
    voucherCode7: '',
    voucherCode8: '',
    voucherCode9: '',
    voucherCode10: '',
    voucherCode11: '',
    voucherCode12: '',
    displayNone: '',
    infoReserveList: '',

    messageVoucher1: '',
    colorMessage1: '',
    messageVoucher2: '',
    colorMessage2: '',
    messageVoucher3: '',
    colorMessage3: '',
    messageVoucher4: '',
    colorMessage4: '',
    messageVoucher5: '',
    colorMessage5: '',
    messageVoucher6: '',
    colorMessage6: '',
    messageVoucher7: '',
    colorMessage7: '',
    messageVoucher8: '',
    render: false,
    colorMessage8: '',
    messageVoucher9: '',
    colorMessage9: '',
    messageVoucher10: '',
    colorMessage10: '',
    messageVoucher11: '',
    colorMessage11: '',
    messageVoucher12: '',
    colorMessage12: '',
    participantsConfig: [],
    message: '',
    // addOptionsArr: [
    //     {
    //         value: "Transport sprzętu do kitesurfingu",
    //         id: "Transport sprzętu do kitesurfingu",
    //         description: "W cenie, ale musimy o tym wiedzieć",

    //     },
    //     {
    //         value: "Puste miejsce obok",
    //         id: "Puste miejsce obok",
    //         description: "",

    //     }
    // ],
    comments: '',
    paymentOption: 'onetime',
    paymentType: 'wired',
    messageStepOne: '',
    messageStepTwo: '',
    messageStepThree: '',
    messageStepFour: '',
    messageStepFive: '',
    stepNumberOne: false,
    stepNumberTwo: false,
    stepNumberThree: false,
    stepNumberFour: false,
    stepNumberFive: false,
    stepNumberSix: false,
    offerId: this.props.location.state.id,
    nameTrip: this.props.location.state.nameTrip,
    typeId: this.props.location.state.offerTypeId,
    reservationId: '',
    siteId: 1,
    accommodationOption: 'single',
    participantsCount: '',
    termsAccepted: 0,
    groupRebateAvailable: false,
    groupCode: '',
    groupCodeCreate: '',
    trueMessage: '',
    errorMessage: '',
    secondTrueMessage: '',
    trueMessageVisible: false,
    errorMessage2: '',
    yachtClass: 'Klasa PREMIER',
    accommodationConfig: [],
    journeyConfig: [],
    insuranceConfig: [],
    Reservation: {},
    trainingsConfig: [],
    addonsConfig: [],
    stepOne: false,
    stepTwo: false,
    stepThree: false,
    stepFour: false,
    stepFive: false,
    stepSix: false,
    stepSubmit: false,
    detailsLater: true,
    insurance: 'Tak',
    numberOfreservation: '',
    name: 'Jacht PREMIER',
    addOptions: [],
    allChecked: false,
    list: [
      {
        id: 1,
        name: '* Zgadzam się na przetwarzanie moich danych przez Taksidi Sp. z o.o.',
        link: '',
        isChecked: false,
      },
      {
        id: 2,
        name: '* Akceptuję Warunki Uczestnictwa w Imprezach Turystycznych organizowanych przez firmę Taksidi sp. z o.o.',
        link: { regulamin },
        isChecked: false,
      },
    ],
    ambassadorCode: '',
  };

  /*   const cachedUrlName = getOfferCachedUrlName(offerId);
  return dispatch(
    fetchData(
      "/v1/offers/details",
      {
        siteId: 1,
        offerId: offerId,
      },
      cachedUrlName
    )
  );
};
 */

  handleChange = (e) => {
    // const maxPlace = this.state.Reservation.Data.map((el) => Math.max.apply(Math, el.MaxPlacesPerRoom))
    if (e.target.type === 'checkbox') {
      this.setState({
        [e.target.name]: e.target.checked,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }

    //  if (this.state.skipper === false){
    //         this.setState({
    //             participantsCountWhole: maxPlace - 1,
    //         })
    //     }
  };

  handleChangeParticipants = (e) => {
    this.setState({
      [e.target.name]: e.target.value - 1,
    });
  };

  showCreateCode = () => {
    this.setState({
      groupRebateAvailable: !this.state.groupRebateAvailable,
    });
  };

  handleChange3 = (e) => {
    this.setState({
      [e.target.name]: e.target.checked ? 1 : 0,
    });
  };

  handleChange4 = (id, event) => {
    const { accommodationConfig } = this.state;
    const elIndex = accommodationConfig.findIndex((el) => el.id === id);

    const StepTwoArr = this.state.Reservation.Data.find((el) => el.Step === 2);
    const accommodationArray = StepTwoArr.ResortsList.map((el) =>
      el.RoomsList.map((el) => el)
    );
    const updatedAccommodationArray =
      accommodationArray[4] !== undefined
        ? accommodationArray[0].concat(
            accommodationArray[1],
            accommodationArray[2],
            accommodationArray[3],
            accommodationArray[4]
          )
        : accommodationArray[3] !== undefined
        ? accommodationArray[0].concat(
            accommodationArray[1],
            accommodationArray[2],
            accommodationArray[3]
          )
        : accommodationArray[2] !== undefined
        ? accommodationArray[0].concat(
            accommodationArray[1],
            accommodationArray[2]
          )
        : accommodationArray[1] !== undefined
        ? accommodationArray[0].concat(accommodationArray[1])
        : accommodationArray[0];
    const updatedAccommodationArray2 = updatedAccommodationArray.map((el) => ({
      ...el,
      canAvailablePlace:
        el.Availability.RoomsAvailableForReservationCount *
          el.Availability.AccommodationsCount >
          el.Availability.ReservedPlacesCount &&
        el.Availability.RoomsAvailableForReservationCount *
          el.Availability.AccommodationsCount !==
          el.Availability.ReservedPlacesCount,
    }));

    const StepTwoArrJourney = StepTwoArr.Journeys.filter(
      (el) => el.Available === true
    );
    const StepTwoArrJourneyName =
      StepTwoArrJourney[0].Name.includes('własny') ||
      StepTwoArrJourney[0].Name.includes('Własny');
    const onlyOneJourney =
      StepTwoArrJourney.length === 1 && StepTwoArrJourneyName === true;

    if (event.target.type === 'checkbox') {
      accommodationConfig[elIndex].sameAsTheFirst = event.target.checked;
    } else {
      accommodationConfig[elIndex].roomId = event.target.value;
      accommodationConfig[elIndex].bedroomChosen = false;
      accommodationConfig[elIndex].nameClass = event.target.id;
    }

    if (accommodationConfig[elIndex].sameAsTheFirst) {
      accommodationConfig[elIndex].roomId = accommodationConfig[0].roomId;
      accommodationConfig[elIndex].journeyId = accommodationConfig[0].journeyId;
    }

    if (
      onlyOneJourney &&
      updatedAccommodationArray2.some(
        (el) =>
          this.state.accommodationConfig[0].roomId === el.RoomId &&
          !el.canAvailablePlace
      )
    ) {
      accommodationConfig.map((el) => (el.sameAsTheFirst = true));
    }
    // {(el?.Availability?.RoomsAvailableForReservationCount * el?.Availability?.AccommodationsCount) - el?.Availability?.ReservedPlacesCount}

    if (
      updatedAccommodationArray2.some(
        (el) =>
          this.state.accommodationConfig[0].roomId === el.RoomId &&
          !el.canAvailablePlace
      )
    ) {
      accommodationConfig.map((el) => (el.reserveListJourneyOption = false));
      this.setState({
        infoReserveList:
          'Zapisz się na Listę Rezerwową! Jak tylko zwolni się miejsce lub zbierzemy grupę na dodatkową łódkę, to otrzymasz powiadomienie i wskoczysz na Listę Główną!',
      });
    } else if (
      updatedAccommodationArray2.some(
        (el) =>
          this.state.accommodationConfig[0].roomId === el.RoomId &&
          el.Availability.RoomsAvailableForReservationCount *
            el.Availability.AccommodationsCount >
            el.Availability.ReservedPlacesCount &&
          el.Availability.RoomsAvailableForReservationCount *
            el.Availability.AccommodationsCount !==
            el.Availability.ReservedPlacesCount &&
          this.state.Reservation.Data[0].Config.ParticipantsCount >
            el?.Availability?.RoomsAvailableForReservationCount *
              el?.Availability?.AccommodationsCount -
              el?.Availability?.ReservedPlacesCount
      )
    ) {
      const countFreePlaces = updatedAccommodationArray2.filter(
        (el) => this.state.accommodationConfig[0].roomId === el.RoomId
      );
      const countFreePlaces2 =
        countFreePlaces[0].Availability?.RoomsAvailableForReservationCount *
          countFreePlaces[0].Availability?.AccommodationsCount -
        countFreePlaces[0].Availability?.ReservedPlacesCount;
      const freePlaces =
        countFreePlaces2 === 1 ||
        countFreePlaces2 === 2 ||
        countFreePlaces2 === 3 ||
        countFreePlaces2 === 4
          ? `dostępne ${countFreePlaces2} wolne miejsca`
          : `dostępnych ${countFreePlaces2} wolnych miejsc`;
      accommodationConfig[elIndex].reserveListJourneyOption = true;
      this.setState({
        infoReserveList: `Na wybranym jachcie jest ${freePlaces}. Jeżeli wybierzesz tę opcję, twoja rezerwacja trafi na listę rezerwową.`,
      });
    } else {
      accommodationConfig[elIndex].reserveListJourneyOption = true;
      this.setState({
        infoReserveList: '',
      });
    }
    this.setState({ accommodationConfig });
    this.setState({
      colorRoom: '',
    });
  };

  handleChangePointId = (id, event) => {
    this.setState({
      journeyPointId: event.target.value,
    });
  };

  handleChangeParticipantWholeYacht = (id, event) => {
    const StepTwoArr = this.state.Reservation.Data.find((el) => el.Step === 2);
    const accommodationArray = StepTwoArr.ResortsList.map((el) =>
      el.RoomsList.map((el) => el)
    );
    const updatedAccommodationArray =
      accommodationArray[4] !== undefined
        ? accommodationArray[0].concat(
            accommodationArray[1],
            accommodationArray[2],
            accommodationArray[3],
            accommodationArray[4]
          )
        : accommodationArray[3] !== undefined
        ? accommodationArray[0].concat(
            accommodationArray[1],
            accommodationArray[2],
            accommodationArray[3]
          )
        : accommodationArray[2] !== undefined
        ? accommodationArray[0].concat(
            accommodationArray[1],
            accommodationArray[2]
          )
        : accommodationArray[1] !== undefined
        ? accommodationArray[0].concat(accommodationArray[1])
        : accommodationArray[0];
    const updatedAccommodationArray2 = updatedAccommodationArray.map((el) => ({
      ...el,
      canAvailablePlace:
        el.Availability.RoomsAvailableForReservationCount *
          el.Availability.AccommodationsCount >
          el.Availability.ReservedPlacesCount &&
        el.Availability.RoomsAvailableForReservationCount *
          el.Availability.AccommodationsCount !==
          el.Availability.ReservedPlacesCount,
    }));

    this.setState(
      {
        participantsCountWhole: event.target.title,
        roomId: event.target.value,
        accommodationConfigNew: [],
        skipper: this.state.nameTrip.includes('The Boat Trip')
          ? 'TAK'
          : this.state.skipper,
      },
      () => {
        const part = parseInt(this.state.participantsCountWhole) + 1;

        for (
          var i = 1;
          i <
          (this.state.nameTrip.includes('The Boat Trip')
            ? part
            : this.state.skipper === 'TAK'
            ? part
            : this.state.skipper === 'NIE'
            ? this.state.participantsCountWhole
            : null);
          i++
        ) {
          this.state.accommodationConfigNew.push({
            id: i,
            userNum: i,
            roomId: '',
            name: '',
            inputAddNameActive: false,
            nameClass: '',
            valueSurname: '',
            newName: true,
            valueName: '',
            yachtClass: '',
            bedroomChosen: false,
            sameAsTheFirst: true,
            reserveListJourneyOption: true,
            journeyId: '',
            journeyPointId: '',
          });
        }

        setTimeout(() => {
          if (
            updatedAccommodationArray2.some(
              (el) =>
                this.state.roomId === el.RoomId &&
                el.Availability.RoomsAvailableForReservationCount *
                  el.Availability.AccommodationsCount -
                  el.Availability.ReservedPlacesCount <
                  el.Availability.AccommodationsCount
            )
          ) {
            this.setState({
              infoReserveList:
                'Zapisz się na Listę Rezerwową! Jak tylko zwolni się miejsce lub zbierzemy grupę na dodatkową łódkę, to otrzymasz powiadomienie i wskoczysz na Listę Główną!',
            });
          } else {
            this.setState({
              infoReserveList: '',
            });
          }
          this.setState({
            accommodationConfig: this.state.accommodationConfigNew,
          });
        }, 100);
      }
    );
  };

  handleChangeJourneyWholeYacht = (id, event) => {
    this.setState({
      journeyPointId: '',
      journeyId: event.target.value,
    });
  };

  handleChangeAddTransport = (id, event) => {
    const { accommodationConfig } = this.state;
    const elIndex = accommodationConfig.findIndex((el) => el.id === id);
    accommodationConfig[elIndex].transportAddLuggage = event.target.checked
      ? true
      : false;
    this.setState({ accommodationConfig });
  };

  handleChange27 = (id, event) => {
    const { accommodationConfig } = this.state;
    const elIndex = accommodationConfig.findIndex((el) => el.id === id);
    if (event.target.type === 'checkbox') {
      accommodationConfig[elIndex].bedroomChosen = event.target.checked
        ? true
        : false;
    }
    this.setState({ accommodationConfig });
  };

  handleChange7 = (id, event) => {
    const { insuranceConfig } = this.state;

    const elIndex = insuranceConfig.findIndex((el) => el.id === id);

    if (event.target.type === 'checkbox') {
      insuranceConfig[elIndex].sameAsTheFirst = event.target.checked;
    } else {
      insuranceConfig[elIndex].insuranceId = event.target.value;
    }

    if (insuranceConfig[elIndex].sameAsTheFirst) {
      insuranceConfig[elIndex].insuranceId = insuranceConfig[0].insuranceId;
      insuranceConfig[elIndex].resignationInsuranceAccepted =
        insuranceConfig[0].resignationInsuranceAccepted;
      insuranceConfig[elIndex].quarantineAssistanceAccepted =
        insuranceConfig[0].quarantineAssistanceAccepted;
    }

    this.setState({ insuranceConfig });
  };

  handleChange24 = (id, event) => {
    const { participantsConfig } = this.state;
    const elIndex = participantsConfig.findIndex((el) => el.id === id);
    if (event.target.type === 'checkbox') {
      participantsConfig[elIndex].haveAVoucher = event.target.checked;
    }

    this.setState({ participantsConfig });
  };

  handleChange8 = (id, event) => {
    const { insuranceConfig } = this.state;
    const elIndex = insuranceConfig.findIndex((el) => el.id === id);
    insuranceConfig[elIndex].insuranceId = event.target.value;

    this.setState({ insuranceConfig });
  };

  handleChange9 = (id, event) => {
    const { insuranceConfig } = this.state;
    const elIndex = insuranceConfig.findIndex((el) => el.id === id);
    insuranceConfig[elIndex].resignationInsuranceAccepted = event.target.value;
    this.setState({ insuranceConfig });
  };

  handleAssistanceChange = (id, event) => {
    const { insuranceConfig } = this.state;
    const elIndex = insuranceConfig.findIndex((el) => el.id === id);
    insuranceConfig[elIndex].quarantineAssistanceAccepted = event.target.value;
    this.setState({ insuranceConfig });
  };

  handleChange5 = (id, event) => {
    const { accommodationConfig } = this.state;
    const elIndex = accommodationConfig.findIndex((el) => el.id === id);
    accommodationConfig[elIndex].journeyId = event.target.value;
    accommodationConfig[elIndex].journeyPointId = '';
    this.setState({
      accommodationConfig,
    });
    // if (this.state.accommodationConfig[0].journeyPointId === "" && !this.state.accommodationConfig[0].journeyId.includes("Dojazd")) {
    this.setState({
      messageStepTwoJourneyPointId: '',
      colorJourneyPoinId: '',
      messageStepTwoJourneyPointId2: ' ',
      colorJourney: '',
      buttonAvailableStepTwo: true,
    });
    // }
  };

  handleChange6 = (id, event) => {
    const { accommodationConfig } = this.state;
    const elIndex = accommodationConfig.findIndex((el) => el.id === id);
    accommodationConfig[elIndex].journeyPointId = event.target.value;
    this.setState({ accommodationConfig });

    if (
      accommodationConfig[0].journeyPointId !== '' &&
      accommodationConfig[0].journeyId === '1'
    ) {
      this.setState({
        messageStepTwoJourneyPointId: '',
        colorJourneyPoinId: '',
        messageStepTwoJourneyPointId2: '',
      });
    }
  };

  handleChange10 = (id, event) => {
    const { trainingsConfig } = this.state;
    const elIndex2 = trainingsConfig.map((el) => el.trainingsSelected);
    const elIndex = elIndex2.findIndex((el) => el.id === id);
    elIndex2[elIndex].trainingId = event.target.value;
    this.setState({ trainingsConfig });
  };

  handleChange11 = (id, event) => {
    const { trainingsConfig } = this.state;
    const elIndex2 = trainingsConfig.map((el) => el.trainingsSelected);
    const elIndex = elIndex2.findIndex((el) => el.id === id);
    elIndex2[elIndex].trainingOptionId = event.target.value;
    this.setState({ trainingsConfig });
  };

  handleChange12 = (id, event) => {
    // secondNewArray[elIndex3].addonId = event.target.value

    // const { trainingsConfig } = this.state;
    // const elIndex2 = trainingsConfig.map(el => el.addonsSelected);
    // const elIndex = elIndex2.findIndex((el) => el.id === id)
    // const elIndex3 = elIndex2.map(el => el[0]);
    // elIndex3[0].addonId = event.target.value
    // // let newArray = [...trainingsConfig[elIndex].addonsSelected.addonId, event.target.value];
    // if (trainingsConfig[elIndex].addonsSelected.addonId.includes(event.target.value)) {
    //     newArray = newArray.filter(el => el !== event.target.value);
    // }
    // trainingsConfig[elIndex].addonsSelected.addonId = newArray
    // this.setState({ trainingsConfig });
    // this.setState({
    //     messageSizeAddon: ""
    // })

    const { trainingsConfig } = this.state;
    const elIndex2 = trainingsConfig.map((el) => el.addonsSelected);
    const elIndex = elIndex2.findIndex((el) => el.id === id);
    elIndex2[elIndex].addonId = event.target.value;
    elIndex2[elIndex].addonOptionId = '0';
    this.setState({ trainingsConfig });

    this.setState({
      messageSizeAddon: '',
    });
  };

  handleChange13 = (id, event) => {
    // const { trainingsConfig } = this.state;
    // const elIndex2 = trainingsConfig.map(el => el.addonsSelected);
    // const elIndex = elIndex2.findIndex((el) => el.id === id)
    // const stepFourArrAddonId = this.state.Reservation.Data[3].AddonsList.map(el => el.AddonId)

    // let arr = event.target.value;
    // trainingsConfig[elIndex].addonsSelected.addonOptionId[0] = [arr];
    // let newArray = trainingsConfig[elIndex].addonsSelected.addonId.length === 1 ? [arr] : [...trainingsConfig[elIndex].addonsSelected.addonOptionId, arr];
    // if (trainingsConfig[elIndex].addonsSelected.addonOptionId.includes(event.target.value)) {
    //     newArray = newArray.filter(el => el !== event.target.value);
    // }

    // trainingsConfig[elIndex].addonsSelected.addonOptionId = event.target.value

    // // elIndex2[elIndex].addonOptionId = event.target.value;
    // this.setState({ trainingsConfig });

    // this.setState({
    //     messageSizeAddon: ""
    // })

    const { trainingsConfig } = this.state;
    const elIndex2 = trainingsConfig.map((el) => el.addonsSelected);
    const elIndex = elIndex2.findIndex((el) => el.id === id);
    elIndex2[elIndex].addonOptionId = event.target.value;
    this.setState({ trainingsConfig });

    this.setState({
      messageSizeAddon: '',
    });
  };

  handleChange14 = (id, event) => {
    const { accommodationConfig } = this.state;
    const elIndex = accommodationConfig.findIndex((el) => el.id === id);
    accommodationConfig[elIndex].yachtClass = event.target.value;
    accommodationConfig[elIndex].roomId = '0';
    this.setState({ accommodationConfig });
  };

  handleChange15 = (id, event) => {
    const { value } = event.target;
    const { participantsConfig } = this.state;
    const elIndex = participantsConfig.findIndex((el) => el.id === id);
    participantsConfig[elIndex].firstName = value;
    this.setState({ participantsConfig });

    this.setState({
      colorInputRedFirstName: '',
    });
  };

  handleChange30 = (id, event) => {
    const { value } = event.target;
    const { participantsConfig } = this.state;
    const elIndex = participantsConfig.findIndex((el) => el.id === id);
    participantsConfig[elIndex].password = value;
    this.setState({ participantsConfig });
  };

  handleChange31 = (id, event) => {
    const { checked } = event.target;
    const { participantsConfig } = this.state;
    const elIndex = participantsConfig.findIndex((el) => el.id === id);
    participantsConfig[elIndex].policyAccepted = checked ? 1 : 0;
    this.setState({ participantsConfig });
  };

  handleChange36 = (id, event) => {
    const { checked } = event.target;
    const { participantsConfig } = this.state;
    const elIndex = participantsConfig.findIndex((el) => el.id === id);
    participantsConfig[elIndex].newsletterAccepted = checked ? 1 : 0;
    this.setState({ participantsConfig });
  };

  handleChange25 = (id, event) => {
    const { value } = event.target;
    const { participantsConfig } = this.state;
    const elIndex = participantsConfig.findIndex((el) => el.id === id);
    participantsConfig[elIndex].country = value;
    this.setState({ participantsConfig });
  };
  handleChange22 = (id, event) => {
    const { checked } = event.target;
    const { participantsConfig } = this.state;
    const elIndex = participantsConfig.findIndex((el) => el.id === id);

    participantsConfig[elIndex].fillInLater = checked ? 1 : 0;

    this.setState({ participantsConfig });
  };

  handleChange16 = (id, event) => {
    const { value } = event.target;
    const { participantsConfig } = this.state;
    const elIndex = participantsConfig.findIndex((el) => el.id === id);
    participantsConfig[elIndex].lastName = value;
    this.setState({ participantsConfig });

    this.setState({
      colorInputRedLastName: '',
    });
  };

  handleChange17 = (id, event) => {
    const { value } = event.target;
    const { participantsConfig } = this.state;
    const elIndex = participantsConfig.findIndex((el) => el.id === id);
    participantsConfig[elIndex].birthDate = value;
    this.setState({ participantsConfig });
  };

  handleChange18 = (id, event) => {
    const { value } = event.target;
    const { participantsConfig } = this.state;
    const elIndex = participantsConfig.findIndex((el) => el.id === id);
    participantsConfig[elIndex].idDocument = value;

    this.setState({ participantsConfig });
  };

  handleChange19 = (id, event) => {
    const { value } = event.target;
    event.preventDefault();
    const { participantsConfig } = this.state;
    const elIndex = participantsConfig.findIndex((el) => el.id === id);
    // var phoneString = /\+?\(?([0-9]{2})\)?[ ]?\(?([0-9]{3})\)?[- ]?\(?([0-9]{3})\)?[- ]?\(?([0-9]{3})\)?/;
    var phoneString = /[0-9]/;
    participantsConfig[elIndex].phone =
      value.match(phoneString) && value.length > 8 && value.length < 16
        ? (this.setState({ validatePhone: true }),
          (participantsConfig[elIndex].phone = value))
        : this.setState({ validatePhone: false });

    this.setState({ participantsConfig });
  };

  handleChange20 = (id, event) => {
    const { value } = event.target;
    const { participantsConfig } = this.state;
    const elIndex = participantsConfig.findIndex((el) => el.id === id);
    participantsConfig[elIndex].email = value;
    this.setState({ participantsConfig });
  };

  handleChange21 = (id, event) => {
    const { value } = event.target;
    const { participantsConfig } = this.state;
    const elIndex = participantsConfig.findIndex((el) => el.id === id);
    participantsConfig[elIndex].gender = value;
    this.setState({ participantsConfig });
  };

  handleChange23 = (id, event) => {
    const { participantsConfig } = this.state;
    const elIndex = participantsConfig.findIndex((el) => el.id === id);
    participantsConfig[elIndex].voucherCode = event.target.value;
    this.setState({ participantsConfig });
  };

  handleCheckboxChange = (event) => {
    let newArray = [...this.state.addOptions, event.target.id];
    if (this.state.addOptions.includes(event.target.id)) {
      newArray = newArray.filter((el) => el !== event.target.id);
    }
    this.setState({
      addOptions: newArray,
    });
  };

  handleClickMore = (event) => {
    event.preventDefault();
    if (
      this.state.participantsCount <
      this.state.Reservation.Data.map((el) =>
        Math.max.apply(Math, el.MaxPlacesPerRoom)
      )
    ) {
      this.setState({
        participantsCount: this.state.participantsCount + 1,
      });
    }
  };
  handleClickLess = (event) => {
    event.preventDefault();
    if (this.state.participantsCount > 1) {
      this.setState({
        participantsCount: this.state.participantsCount - 1,
      });
    }
  };

  //     handleClickMore1 = (event) => {
  //         event.preventDefault();
  // if (this.state.skipper === true) {
  //         if (this.state.participantsCountWhole < this.state.Reservation.Data.map((el) => Math.max.apply(Math, el.MaxPlacesPerRoom)) - 1) {
  //             this.setState({
  //                 participantsCountWhole: this.state.participantsCountWhole + 1,
  //             })
  //         }
  //     } else {
  //         if (this.state.participantsCountWhole < this.state.Reservation.Data.map((el) => Math.max.apply(Math, el.MaxPlacesPerRoom))) {
  //             this.setState({
  //                 participantsCountWhole: this.state.participantsCountWhole + 1,
  //             })
  //         }
  //     }
  // }
  // handleClickLess1 = (event) => {
  //     event.preventDefault();
  //     if (this.state.participantsCountWhole > 1) {
  //         this.setState({
  //             participantsCountWhole: this.state.participantsCountWhole - 1,
  //         })
  //     }
  // }

  handleAddName = (id) => {
    const { accommodationConfig } = this.state;
    const elIndex = accommodationConfig.findIndex((el) => el.id === id);
    accommodationConfig[elIndex].inputAddNameActive = true;

    this.setState({ accommodationConfig });
  };

  handleSaveName = (id) => {
    const { accommodationConfig } = this.state;
    const elIndex = accommodationConfig.findIndex((el) => el.id === id);
    accommodationConfig[elIndex].newName = false;

    this.setState({ accommodationConfig });
  };

  handleEditNameSingle = (id) => {
    const { accommodationConfig } = this.state;
    const elIndex = accommodationConfig.findIndex((el) => el.id === id);
    accommodationConfig[elIndex].newName = true;

    this.setState({ accommodationConfig });
  };

  handleEditNameWhole = (id) => {
    const { accommodationConfigNew } = this.state;
    const elIndex = accommodationConfigNew.findIndex((el) => el.id === id);
    accommodationConfigNew[elIndex].newName = true;

    this.setState({ accommodationConfigNew });
  };

  // handleEditName1 = (id, event) => {
  //     const { accommodationConfig } = this.state;
  //     const elIndex = accommodationConfig.findIndex((el) => el.id === id);
  //     accommodationConfig[elIndex].valueName = event.target.value;
  //     this.setState({ accommodationConfig });
  // }

  handleEditNameWholeReservation = (id, event) => {
    const { accommodationConfigNew } = this.state;
    const elIndex = accommodationConfigNew.findIndex((el) => el.id === id);
    accommodationConfigNew[elIndex].valueName = event.target.value;
    this.setState({ accommodationConfigNew });
  };

  handleEditNameWholeReservation2 = (id, event) => {
    const { accommodationConfigNew } = this.state;
    const elIndex = accommodationConfigNew.findIndex((el) => el.id === id);
    accommodationConfigNew[elIndex].valueSurname = event.target.value;
    this.setState({ accommodationConfigNew });
  };

  handleEditNameSingleReservation = (id, event) => {
    const { accommodationConfig } = this.state;
    const elIndex = accommodationConfig.findIndex((el) => el.id === id);
    accommodationConfig[elIndex].valueName = event.target.value;
    this.setState({ accommodationConfig });
  };

  handleEditNameSingleReservation2 = (id, event) => {
    const { accommodationConfig } = this.state;
    const elIndex = accommodationConfig.findIndex((el) => el.id === id);
    accommodationConfig[elIndex].valueSurname = event.target.value;
    this.setState({ accommodationConfig });
  };

  handlebackToStepTwo = () => {
    this.setState({
      buttonGrey: false,
      stepNumberThree: false,
    });
    fetch(`${APIbackend}/v1/reservations/accommodations/get`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        siteId: this.state.siteId,
        reservationId: this.state.reservationId,
      }),
    }).then((response) => {
      response.json().then((data) => {
        const pCount = data.Reservation.Data.find((el) => el.Step === 1);
        const participant = pCount.Config.ParticipantsCount + 1;

        const StepTwoArr = data.Reservation.Data.find((el) => el.Step === 2);
        const StepTwoArrJourney = StepTwoArr.Journeys.filter(
          (el) => el.Available === true
        );
        const StepTwoArrJourneyName =
          StepTwoArrJourney[0].Name.includes('własny') ||
          StepTwoArrJourney[0].Name.includes('Własny');
        const onlyOneJourney =
          StepTwoArrJourney.length === 1 && StepTwoArrJourneyName === true;

        this.setState({
          accommodationConfig: [],
        });

        for (var i = 1; i < participant; i++) {
          this.state.accommodationConfig.push({
            id: i,
            userNum: i,
            roomId: '',
            messageOnePersonJourney: '',
            yachtClass: '',
            inputAddNameActive: false,
            valueSurname: '',
            valueName: '',
            newName: true,
            nameClass: '',
            bedroomChosen: false,
            sameAsTheFirst: true,
            reserveListJourneyOption: true,
            journeyId: onlyOneJourney ? StepTwoArrJourney[0].JourneyId : '',
            journeyPointId: '',
            transportAddLuggage: false,
          });
        }
        this.setState({
          stepNumberOne: true,
          stepTwo: true,
          stepThree: false,
          Reservation: data.Reservation,
          accommodationConfig: this.state.accommodationConfig,
        });
      });
    });
  };

  handleBackToStepThree = () => {
    this.setState({
      stepNumberFour: false,
    });
    fetch(`${APIbackend}/v1/reservations/insurance/get`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        siteId: this.state.siteId,
        reservationId: this.state.reservationId,
      }),
    }).then((response) => {
      response.json().then((data) => {
        const pCount = data.Reservation.Data.find((el) => el.Step === 1);
        const participant = pCount.Config.ParticipantsCount + 1;
        this.setState({
          insuranceConfig: [],
        });

        for (var x = 1; x < participant; x++) {
          this.state.insuranceConfig.push({
            id: x,
            userNum: x,
            resignationInsuranceAccepted: '0',
            quarantineAssistanceAccepted: '0',
            valueSurname: '',
            valueName: '',
            sameAsTheFirst: true,

            insuranceId: '1',
            firstName: '',
            lastName: '',
          });
        }
        this.setState({
          stepTwo: false,
          stepFour: false,
          stepThree: true,
          stepNumberTwo: true,
          Reservation: data.Reservation,
          insuranceConfig: this.state.insuranceConfig,
        });
      });
    });
  };

  handleBackToStepFour = () => {
    this.setState({
      stepNumberFive: false,
    });
    fetch(`${APIbackend}/v1/reservations/addons/get`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        siteId: this.state.siteId,
        reservationId: this.state.reservationId,
      }),
    }).then((response) => {
      response.json().then((data) => {
        const pCount = data.Reservation.Data.find((el) => el.Step === 1);
        const participant = pCount.Config.ParticipantsCount + 1;
        this.setState({
          trainingsConfig: [],
        });

        for (var z = 1; z < participant; z++) {
          this.state.trainingsConfig.push({
            userNum: z,
            valueSurname: '',
            valueName: '',
            trainingsSelected: {
              id: z,
              trainingId: '',
              trainingOptionId: '',
            },
            addonsSelected: {
              id: z,
              addonId: '',
              addonOptionId: '',
            },
          });
        }

        this.setState({
          stepThree: false,
          stepFour: true,
          stepFive: false,
          stepNumberThree: true,
          Reservation: data.Reservation,
          trainingsConfig: this.state.trainingsConfig,
          addonsConfig: this.state.addonsConfig,
        });
      });
    });
  };

  handleBackToStepFive = () => {
    this.setState({
      stepNumberSix: false,
    });
    fetch(`${APIbackend}/v1/reservations/participants/get`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        siteId: this.state.siteId,
        reservationId: this.state.reservationId,
      }),
    }).then((response) => {
      response.json().then((data) => {
        const pCount = data.Reservation.Data.find((el) => el.Step === 1);
        const participant = pCount.Config.ParticipantsCount + 1;
        const StepFiveArr = data.Reservation.Data.find((el) => el.Step === 5);
        this.setState({
          participantsConfig: [],
        });

        for (var p = 1; p < participant; p++) {
          this.state.participantsConfig.push({
            id: p,
            userNum: p,
            valueSurname: '',
            wrongMessage:
              p === 1
                ? null
                : p !== 1 &&
                  this.state.Reservation.Data[2].Config.InsuranceConfig[p - 1]
                    .resignationInsuranceAccepted === '1'
                ? 'Dla tego uczestnika wybrano ubezpieczenie od kosztów rezygnacji, w związku z tym wymagane jest podanie imienia, nazwiska i daty urodzenia (pozostałe dane możesz uzupełnić później).'
                : null,
            valueName: '',
            fillInLater:
              p === 1
                ? 0
                : this.state.Reservation.Data[2].Config.InsuranceConfig[p - 1]
                    .resignationInsuranceAccepted === '1'
                ? false
                : this.state.accommodationOption === 'cabin' ||
                  this.state.accommodationOption === 'single'
                ? 0
                : 1,
            firstName:
              p === 1 && StepFiveArr.Account !== null
                ? StepFiveArr.Account.FirstName
                : '',

            password: '',
            policyAccepted: 0,
            newsletterAccepted: 0,
            lastName:
              p === 1 && StepFiveArr.Account !== null
                ? StepFiveArr.Account.LastName
                : '',
            country:
              p === 1 &&
              StepFiveArr.Account !== null &&
              StepFiveArr.Account.Country === null
                ? 'Polska'
                : p === 1 && StepFiveArr.Account !== null
                ? StepFiveArr.Account.Country
                : 'Polska',
            birthDate:
              p === 1 && StepFiveArr.Account !== null
                ? StepFiveArr.Account.BirthDate
                : '',
            idDocument:
              p === 1 && StepFiveArr.Account !== null
                ? StepFiveArr.Account.IdDocument
                : '',
            gender:
              p === 1 && StepFiveArr.Account !== null
                ? StepFiveArr.Account.Gender
                : '',
            phone:
              p === 1 && StepFiveArr.Account !== null
                ? StepFiveArr.Account.Phone
                : '',
            email:
              p === 1 && StepFiveArr.Account !== null
                ? StepFiveArr.Account.Email
                : '',
            // haveAVoucher: p === 1 ? false : true,
            haveAVoucher: false,
          });
        }

        this.setState({
          stepFour: false,
          stepFive: true,
          stepSix: false,
          stepNumberFour: true,
          participantsConfig: this.state.participantsConfig,
          Reservation: data.Reservation,
        });
      });
    });
  };

  handleChange2 = (e) => {
    let elName = e.target.name;
    let checked = e.target.checked;
    this.setState((prevState) => {
      let { list, allChecked } = prevState;
      if (elName === 'checkAll') {
        allChecked = checked;
        list = list.map((el) => ({ ...el, isChecked: checked }));
      } else {
        list = list.map((el) =>
          el.name === elName ? { ...el, isChecked: checked } : el
        );
        allChecked = list.every((el) => el.isChecked);
      }
      return { list, allChecked };
    });
  };

  handleSubmitVoucher = (id) => {
    fetch(`${APIbackend}/v1/rebates/voucher/redeem`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        siteId: this.state.siteId,
        reservationId: this.state.reservationId,
        voucherCode:
          id === 1
            ? this.state.voucherCode1
            : id === 2
            ? this.state.voucherCode2
            : id === 3
            ? this.state.voucherCode3
            : id === 4
            ? this.state.voucherCode4
            : id === 5
            ? this.state.voucherCode5
            : id === 6
            ? this.state.voucherCode6
            : id === 7
            ? this.state.voucherCode7
            : id === 8
            ? this.state.voucherCode8
            : id === 9
            ? this.state.voucherCode9
            : id === 10
            ? this.state.voucherCode10
            : id === 11
            ? this.state.voucherCode11
            : id === 12
            ? this.state.voucherCode12
            : null,
        userNum: id,
      }),
    }).then((response) => {
      response.json().then(() => {
        if (response.status === 200 && id === 1) {
          this.setState({
            messageVoucher1: '✔ Voucher został naliczony na poczet rezerwacji.',
            colorMessage1: 'green',
          });
          fetch(`${APIbackend}/v1/reservations/confirmation/get`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
              siteId: this.state.siteId,
              reservationId: this.state.reservationId,
            }),
          }).then((response) => {
            response.json().then((data) => {
              this.setState({
                Reservation: data.Reservation,
              });
            });
          });
        }
        if (response.status === 200 && id === 2) {
          this.setState({
            messageVoucher2: '✔ Voucher został naliczony na poczet rezerwacji.',
            colorMessage2: 'green',
          });
        }
        if (response.status === 200 && id === 3) {
          this.setState({
            messageVoucher3: '✔ Voucher został naliczony na poczet rezerwacji.',
            colorMessage3: 'green',
          });
        }

        if (response.status === 200 && id === 4) {
          this.setState({
            messageVoucher4: '✔ Voucher został naliczony na poczet rezerwacji.',
            colorMessage4: 'green',
          });
        }

        if (response.status === 200 && id === 5) {
          this.setState({
            messageVoucher5: '✔ Voucher został naliczony na poczet rezerwacji.',
            colorMessage5: 'green',
          });
        }

        if (response.status === 200 && id === 6) {
          this.setState({
            messageVoucher6: '✔ Voucher został naliczony na poczet rezerwacji.',
            colorMessage6: 'green',
          });
        }

        if (response.status === 200 && id === 7) {
          this.setState({
            messageVoucher7: '✔ Voucher został naliczony na poczet rezerwacji.',
            colorMessage7: 'green',
          });
        }

        if (response.status === 200 && id === 8) {
          this.setState({
            messageVoucher8: '✔ Voucher został naliczony na poczet rezerwacji.',
            colorMessage8: 'green',
          });
        }

        if (response.status === 200 && id === 9) {
          this.setState({
            messageVoucher9: '✔ Voucher został naliczony na poczet rezerwacji.',
            colorMessage9: 'green',
          });
        }

        if (response.status === 200 && id === 10) {
          this.setState({
            messageVoucher10: 'Voucher został naliczony na poczet rezerwacji.',
            colorMessage10: 'green',
          });
        }

        if (response.status === 200 && id === 11) {
          this.setState({
            messageVoucher11:
              '✔ Voucher został naliczony na poczet rezerwacji.',
            colorMessage11: 'green',
          });
        }

        if (response.status === 200 && id === 12) {
          this.setState({
            messageVoucher12:
              '✔ Voucher został naliczony na poczet rezerwacji.',
            colorMessage12: 'green',
          });
        }

        if (response.status !== 200 && id === 1) {
          this.setState({
            messageVoucher1:
              'Kod vouchera jest nieprawidłowy lub został już wykorzystany',
            colorMessage1: 'red',
          });
        }

        if (response.status !== 200 && id === 2) {
          this.setState({
            messageVoucher2:
              'Kod vouchera jest nieprawidłowy lub został już wykorzystany',
            colorMessage2: 'red',
          });
        }

        if (response.status !== 200 && id === 3) {
          this.setState({
            messageVoucher3:
              'Kod vouchera jest nieprawidłowy lub został już wykorzystany',
            colorMessage3: 'red',
          });
        }

        if (response.status !== 200 && id === 4) {
          this.setState({
            messageVoucher4:
              'Kod vouchera jest nieprawidłowy lub został już wykorzystany',
            colorMessage4: 'red',
          });
        }

        if (response.status !== 200 && id === 5) {
          this.setState({
            messageVoucher5:
              'Kod vouchera jest nieprawidłowy lub został już wykorzystany',
            colorMessage5: 'red',
          });
        }

        if (response.status !== 200 && id === 6) {
          this.setState({
            messageVoucher6:
              'Kod vouchera jest nieprawidłowy lub został już wykorzystany',
            colorMessage6: 'red',
          });
        }
        if (response.status !== 200 && id === 7) {
          this.setState({
            messageVoucher7:
              'Kod vouchera jest nieprawidłowy lub został już wykorzystany',
            colorMessage7: 'red',
          });
        }
        if (response.status !== 200 && id === 8) {
          this.setState({
            messageVoucher8:
              'Kod vouchera jest nieprawidłowy lub został już wykorzystany',
            colorMessage8: 'red',
          });
        }
        if (response.status !== 200 && id === 9) {
          this.setState({
            messageVoucher9:
              'Kod vouchera jest nieprawidłowy lub został już wykorzystany',
            colorMessage9: 'red',
          });
        }
        if (response.status !== 200 && id === 10) {
          this.setState({
            messageVoucher10:
              'Kod vouchera jest nieprawidłowy lub został już wykorzystany',
            colorMessage10: 'red',
          });
        }
        if (response.status !== 200 && id === 11) {
          this.setState({
            messageVoucher11:
              'Kod vouchera jest nieprawidłowy lub został już wykorzystany',
            colorMessage11: 'red',
          });
        }
        if (response.status !== 200 && id === 12) {
          this.setState({
            messageVoucher12:
              'Kod vouchera jest nieprawidłowy lub został już wykorzystany',
            colorMessage12: 'red',
          });
        }
      });
    });
  };

  handleSubmitAmbassador = (e) => {
    fetch(`${APIbackend}/v1/rebates/ambassador/use`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        siteId: this.state.siteId,
        reservationId: this.state.reservationId,
        ambassadorCode:
          this.state.beSkipper === 'TAK'
            ? 'jestemSkipperemTBT'
            : this.state.ambassadorCode,
      }),
    }).then((response) => {
      response.json().then(() => {
        if (response.status === 200) {
          this.setState({
            messageAmbassador:
              '✔ Kod Ambasadora jest prawidłowy. Zostanie naliczony jako rabat do rezerwacji',
            colorMessageAmb: 'green',
            codeAmbassadorFormVisible: false,
            messageAmbassadorVisible: true,
          });
          fetch(`${APIbackend}/v1/reservations/confirmation/get`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
              siteId: this.state.siteId,
              reservationId: this.state.reservationId,
            }),
          }).then((response) => {
            response.json().then((data) => {
              this.setState({
                Reservation: data.Reservation,
              });
            });
          });
        }

        if (response.status !== 200) {
          this.setState({
            messageAmbassador:
              'Kod Ambasadora jest nieprawidłowy lub został już przypisany do rezerwacji',
            colorMessageAmb: 'red',
          });
        }
      });
    });
  };

  componentDidMount = () => {
    var data = {
      siteId: this.state.siteId,
      offerId: this.state.offerId,
    };
    fetch(`${APIbackend}/v1/reservations/create`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
    }).then((response) => {
      response.json().then((data) => {
        this.setState({
          reservationId: data.Reservation.ReservationId,
        });
        if (response.status === 200) {
          fetch(`${APIbackend}/v1/reservations/details/get`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
              siteId: this.state.siteId,
              reservationId: this.state.reservationId,
            }),
          }).then((response) => {
            response.json().then((data) => {
              if (response.status === 200) {
                // const maxPlace = data.Reservation.Data.map((el) => Math.max.apply(Math, el.MaxPlacesPerRoom))

                this.setState({
                  Reservation: data.Reservation,
                  stepOne: true,
                  participantsCount: 1,
                  stepTwo: false,
                  accommodationOption:
                    data?.Reservation?.Data?.[0]?.CanReservePlace === false
                      ? 'whole'
                      : 'single',
                });
              }
            });
          });
        }
      });
    });
  };

  // componentDidUpdate = () => {
  //     this.setState({

  //     })
  // }

  handleGetStepOne = () => {
    fetch(`${APIbackend}/v1/reservations/details/get`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        siteId: this.state.siteId,
        reservationId: this.state.reservationId,
      }),
    }).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          // const maxPlace = data.Reservation.Data.map((el) => Math.max.apply(Math, el.MaxPlacesPerRoom))

          this.setState({
            Reservation: data.Reservation,
            stepOne: true,
            participantsCount: 1,
            stepTwo: false,
            login: false,
            // redirect: true,
          });
        } else if (response.status !== 200) {
          this.setState({
            login: true,
            stepTwo: false,
            stepOne: false,
            buttons: false,
            stepsNumber: false,
          });
        }
      });
    });
  };

  handleBackToStepOne = () => {
    this.setState({
      stepNumberTwo: false,
      infoReserveList: '',
    });
    fetch(`${APIbackend}/v1/reservations/details/get`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        siteId: this.state.siteId,
        reservationId: this.state.reservationId,
      }),
    }).then((response) => {
      response.json().then((data) => {
        this.setState({
          Reservation: data.Reservation,
          stepOne: true,
          participantsCount: this.state.participantsCount,
          stepTwo: false,
        });
      });
    });
  };

  handleUpdateStepOne = () => {
    this.setState({
      buttonAvailableStepOne: false,
      buttonGrey: false,
      colorRoom: '',
      messageStepTwoRoom: '',
      colorJourney: '',
      messageStepTwoJourney: '',
      messageStepTwo: '',
    });

    fetch(`${APIbackend}/v1/reservations/details/update`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        siteId: this.state.siteId,
        reservationId: this.state.reservationId,
        accommodationOption:
          this.state.accommodationOption === 'cabin'
            ? 'single'
            : this.state.accommodationOption,
        participantsCount:
          this.state.accommodationOption === 'whole'
            ? 1
            : this.state.accommodationOption === 'cabin'
            ? this.state.participantsCountCabin
            : this.state.participantsCount,
        termsAccepted: 1,
      }),
    }).then((response) => {
      response.json().then(() => {
        if (response.status === 200) {
          this.setState({
            buttonAvailableStepOne: false,
          });

          fetch(`${APIbackend}/v1/reservations/accommodations/get`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
              siteId: this.state.siteId,
              reservationId: this.state.reservationId,
            }),
          }).then((response) => {
            response.json().then((data) => {
              const pCount = data.Reservation.Data.find((el) => el.Step === 1);
              const participant = pCount.Config.ParticipantsCount + 1;
              const StepTwoArr = data.Reservation.Data.find(
                (el) => el.Step === 2
              );
              const StepTwoArrJourney = StepTwoArr.Journeys.filter(
                (el) => el.Available === true
              );
              const StepTwoArrJourneyName =
                StepTwoArrJourney[0].Name.includes('własny') ||
                StepTwoArrJourney[0].Name.includes('Własny');
              const onlyOneJourney =
                StepTwoArrJourney.length === 1 &&
                StepTwoArrJourneyName === true;

              const accommodationArray = StepTwoArr.ResortsList.map((el) =>
                el.RoomsList.map((el) => el)
              );
              const updatedAccommodationArray =
                accommodationArray[4] !== undefined
                  ? accommodationArray[0].concat(
                      accommodationArray[1],
                      accommodationArray[2],
                      accommodationArray[3],
                      accommodationArray[4]
                    )
                  : accommodationArray[3] !== undefined
                  ? accommodationArray[0].concat(
                      accommodationArray[1],
                      accommodationArray[2],
                      accommodationArray[3]
                    )
                  : accommodationArray[2] !== undefined
                  ? accommodationArray[0].concat(
                      accommodationArray[1],
                      accommodationArray[2]
                    )
                  : accommodationArray[1] !== undefined
                  ? accommodationArray[0].concat(accommodationArray[1])
                  : accommodationArray[0];
              const updatedAccommodationArray2 = updatedAccommodationArray.map(
                (el) => ({
                  ...el,
                  canAvailablePlace:
                    el.Availability.RoomsAvailableForReservationCount *
                      el.Availability.AccommodationsCount >
                      el.Availability.ReservedPlacesCount &&
                    el.Availability.RoomsAvailableForReservationCount *
                      el.Availability.AccommodationsCount !==
                      el.Availability.ReservedPlacesCount,
                })
              );

              const updatedAccommodationArray2Length =
                updatedAccommodationArray2.length;
              const countRoomWithNotAvalable =
                updatedAccommodationArray2.filter(
                  (el) => !el.canAvailablePlace
                ).length;

              this.setState({
                accommodationConfig: [],
              });

              for (var i = 1; i < participant; i++) {
                this.state.accommodationConfig.push({
                  id: i,
                  userNum: i,
                  roomId: '',
                  messageOnePersonJourney: '',
                  name: 'Jacht PREMIER',
                  inputAddNameActive: false,
                  nameClass: '',
                  valueSurname: '',
                  newName: true,
                  valueName: '',
                  yachtClass: 'Klasa PREMIER',
                  bedroomChosen: false,
                  sameAsTheFirst: true,
                  reserveListJourneyOption: true,
                  journeyId: onlyOneJourney
                    ? StepTwoArrJourney[0].JourneyId
                    : '',
                  journeyPointId: '',
                  transportAddLuggage: false,
                });
              }
              this.setState({
                stepOne: false,
                // linkStepTwo: true,
                stepNumberOne: true,
                stepTwo: true,
                Reservation: data.Reservation,
                accommodationConfig: this.state.accommodationConfig,
                messageStepOne: '',
                buttonAvailableStepOne: true,
                infoReserveList:
                  updatedAccommodationArray2Length === countRoomWithNotAvalable
                    ? 'Zapisz się na Listę Rezerwową! Jak tylko zwolni się miejsce lub zbierzemy grupę na dodatkową łódkę, to otrzymasz powiadomienie i wskoczysz na Listę Główną!'
                    : null,
              });
            });
          });
        }

        if (response.status === 400) {
          this.setState({
            buttonAvailableStepOne: true,
            stepOne: true,
            messageStepOne: 'Uzupełnij wszystkie dane',
          });
        }
      });
    });
  };

  handleCreateGroupCode = () => {
    fetch(`${APIbackend}/v1/rebates/group/create`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        siteId: this.state.siteId,
        reservationId: this.state.reservationId,
        groupCode: this.state.reservationId,
      }),
    }).then((response) => {
      response.json().then((responseData) => {
        if (response.status === 200) {
          this.setState({
            trueMessage:
              '✔ Utworzono nowy kod. Kod grupowy to: ' +
              this.state.reservationId,
            errorMessage: '',
            groupCodeCreate: this.state.reservationId,
            displayNone: 'none',
            errorMessage2: '',
            addGrupCodeVisible: false,
          });
        } else if (responseData.message === 'Group code already exists') {
          this.setState({
            errorMessage: 'Kod grupowy już istnieje',
            trueMessage: '',
            groupCodeCreate: '',
            displayNone: 'none',
          });
        } else if (
          responseData.message === 'Group code already assigned to reservation'
        ) {
          this.setState({
            errorMessage: 'Kod grupowy jest już przypisany do tej rezerwacji',
            trueMessage: '',
            groupCodeCreate: '',
            displayNone: 'none',
          });
        } else {
          this.setState({
            errorMessage: 'Nie można utworzyć kodu grupowego',
            trueMessage: '',
            groupCodeCreate: '',
            displayNone: 'none',
          });
        }
      });
    });
  };

  handleAddGroupCode = () => {
    fetch(`${APIbackend}/v1/rebates/group/join`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        siteId: this.state.siteId,
        reservationId: this.state.reservationId,
        groupCode: this.state.groupCode,
      }),
    }).then((response) => {
      response.json().then((responseData) => {
        if (response.status === 200) {
          this.setState({
            secondTrueMessage: '✔ Witaj w ekipie!',
            errorMessage2: '',
            groupCode: '',
            trueMessageVisible: true,
            addGrupCodeVisible: false,
            buttonGenerateCodeVisible: false,
          });
        } else if (
          responseData.message === 'Group code already assigned to reservation'
        ) {
          this.setState({
            errorMessage2: 'Kod grupowy jest już przypisany do tej rezerwacji',
            secondTrueMessage: '',
            groupCodeCreate: '',
          });
        } else {
          this.setState({
            errorMessage2:
              'Kod jest nieprawidłowy.<br /> Jeżeli posiadasz kod ambasadora(zniżkowy), wpisz go w szóstym kroku rezerwacji.',
            secondTrueMessage: '',
            groupCode: '',
          });
          setTimeout(
            function () {
              this.setState({ render: true }); //After 5 second, set render to true
            }.bind(this),
            7000
          );
        }
      });
    });
  };

  handleUpdateStepTwoWholeYacht = () => {
    this.setState({
      buttonGrey: true,
    });

    fetch(`${APIbackend}/v1/reservations/details/get`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        siteId: this.state.siteId,
        reservationId: this.state.reservationId,
      }),
    }).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          this.setState({
            buttonGrey: true,
          });

          fetch(`${APIbackend}/v1/reservations/details/update`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
              siteId: this.state.siteId,
              reservationId: this.state.reservationId,
              accommodationOption: this.state.accommodationOption,
              participantsCount:
                this.state.skipper === 'TAK' ||
                this.state.nameTrip.includes('The Boat Trip')
                  ? this.state.participantsCountWhole
                  : parseInt(this.state.participantsCountWhole) - 1,
              termsAccepted: 1,
            }),
          }).then((response) => {
            response.json().then(() => {
              if (response.status === 200) {
                this.setState({
                  buttonGrey: true,
                });
                fetch(`${APIbackend}/v1/reservations/accommodations/get`, {
                  method: 'POST',
                  credentials: 'include',
                  body: JSON.stringify({
                    siteId: this.state.siteId,
                    reservationId: this.state.reservationId,
                  }),
                }).then((response) => {
                  response.json().then((data) => {
                    const StepTwoArr = data.Reservation.Data.find(
                      (el) => el.Step === 2
                    );
                    const StepTwoArrJourney = StepTwoArr.Journeys.filter(
                      (el) => el.Available === true
                    );
                    const StepTwoArrJourneyName =
                      StepTwoArrJourney[0].Name.includes('własny') ||
                      StepTwoArrJourney[0].Name.includes('Własny');
                    const onlyOneJourney =
                      StepTwoArrJourney.length === 1 &&
                      StepTwoArrJourneyName === true;

                    // const StepTwoArr = this.state.Reservation.Data.find((el) => el.Step === 2)
                    const accommodationArray = StepTwoArr.ResortsList.map(
                      (el) => el.RoomsList.map((el) => el)
                    );
                    const updatedAccommodationArray =
                      accommodationArray[4] !== undefined
                        ? accommodationArray[0].concat(
                            accommodationArray[1],
                            accommodationArray[2],
                            accommodationArray[3],
                            accommodationArray[4]
                          )
                        : accommodationArray[3] !== undefined
                        ? accommodationArray[0].concat(
                            accommodationArray[1],
                            accommodationArray[2],
                            accommodationArray[3]
                          )
                        : accommodationArray[2] !== undefined
                        ? accommodationArray[0].concat(
                            accommodationArray[1],
                            accommodationArray[2]
                          )
                        : accommodationArray[1] !== undefined
                        ? accommodationArray[0].concat(accommodationArray[1])
                        : accommodationArray[0];
                    const updatedAccommodationArray2 =
                      updatedAccommodationArray.map((el) => ({
                        ...el,
                        canAvailablePlace:
                          el.Availability.RoomsAvailableForReservationCount *
                            el.Availability.AccommodationsCount >
                            el.Availability.ReservedPlacesCount &&
                          el.Availability.RoomsAvailableForReservationCount *
                            el.Availability.AccommodationsCount !==
                            el.Availability.ReservedPlacesCount,
                      }));

                    if (response.status === 200) {
                      const pCount = data.Reservation.Data.find(
                        (el) => el.Step === 1
                      );
                      const participant = pCount.Config.ParticipantsCount + 1;

                      this.setState({
                        newAccConfig: [],
                        buttonGrey: true,
                        journeyId: onlyOneJourney
                          ? StepTwoArrJourney[0].JourneyId
                          : this.state.journeyId,
                      });
                      for (var i = 1; i < participant; i++) {
                        this.state.newAccConfig.push({
                          id: i,
                          userNum: i,
                          roomId: '',
                          name: 'Jacht PREMIER',
                          inputAddNameActive: false,
                          nameClass: '',
                          valueSurname: '',
                          // people: "",
                          newName: true,
                          valueName: '',
                          yachtClass: 'Klasa PREMIER',
                          bedroomChosen: false,
                          sameAsTheFirst: true,
                          reserveListJourneyOption: true,
                          journeyId: onlyOneJourney
                            ? StepTwoArrJourney[0]
                            : this.state.journeyId,
                          journeyPointId: '',
                          transportAddLuggage: false,
                        });
                      }

                      setTimeout(() => {
                        this.setState({
                          accommodationConfig: this.state.newAccConfig,
                        });
                      }, 100);
                      this.setState({
                        stepOne: false,
                        // linkStepTwo: true,
                        stepNumberOne: true,
                        stepTwo: true,
                        Reservation: data.Reservation,
                        messageStepOne: '',
                        accommodationConfig: this.state.accommodationConfig,
                      });

                      if (response.status === 200) {
                        this.setState({
                          buttonGrey: true,
                          accommodationConfig: this.state.accommodationConfig,
                        });
                        fetch(
                          `${APIbackend}/v1/reservations/accommodations/update`,
                          {
                            method: 'POST',
                            credentials: 'include',
                            body: JSON.stringify({
                              siteId: this.state.siteId,
                              reservationId: this.state.reservationId,
                              accommodationConfig:
                                this.state.accommodationConfig.map((el) => ({
                                  userNum: el.userNum,
                                  roomId: this.state.roomId,
                                  optionsChosen: [
                                    {
                                      bedroomChosen: false,
                                    },
                                  ],
                                })),
                              journeyConfig: this.state.accommodationConfig.map(
                                (el) => ({
                                  userNum: el.userNum,
                                  journeyId:
                                    el.sameAsTheFirst === true
                                      ? this.state.journeyId
                                      : el.journeyId,
                                  journeyPointId:
                                    el.sameAsTheFirst === true
                                      ? this.state.journeyPointId
                                      : el.journeyId !== '1'
                                      ? ''
                                      : el.journeyPointId,
                                })
                              ),
                              isReserveList: updatedAccommodationArray2.some(
                                (el) =>
                                  this.state.roomId === el.RoomId &&
                                  !el.canAvailablePlace
                              )
                                ? 1
                                : 0,
                            }),
                          }
                        ).then((response) => {
                          response.json().then((responseData) => {
                            if (response.status === 200) {
                              this.setState({
                                buttonGrey: true,
                                accommodationConfig:
                                  this.state.accommodationConfig,
                              });
                              fetch(
                                `${APIbackend}/v1/reservations/insurance/get`,
                                {
                                  method: 'POST',
                                  credentials: 'include',
                                  body: JSON.stringify({
                                    siteId: this.state.siteId,
                                    reservationId: this.state.reservationId,
                                  }),
                                }
                              ).then((response) => {
                                response.json().then((data) => {
                                  const pCount = data.Reservation.Data.find(
                                    (el) => el.Step === 1
                                  );
                                  const participant =
                                    pCount.Config.ParticipantsCount + 1;
                                  this.setState({
                                    insuranceConfig: [],
                                  });

                                  for (var x = 1; x < participant; x++) {
                                    this.state.insuranceConfig.push({
                                      id: x,
                                      userNum: x,
                                      valueSurname: '',
                                      valueName: '',
                                      quarantineAssistanceAccepted: '0',
                                      resignationInsuranceAccepted: '0',
                                      sameAsTheFirst: true,
                                      insuranceId: '1',
                                      firstName: '',
                                      lastName: '',
                                    });
                                  }
                                  this.setState({
                                    stepTwo: false,
                                    stepThree: true,
                                    stepNumberTwo: true,
                                    buttonGrey: false,
                                    Reservation: data.Reservation,
                                    insuranceConfig: this.state.insuranceConfig,
                                    messageStepTwo: '',
                                    messageStepTwoRoom: '',
                                    messageStepTwoJourney: '',
                                  });
                                });
                              });
                            }
                            if (response.status !== 200) {
                              this.setState({
                                stepTwo: true,
                                stepThree: false,
                                stepNumberTwo: false,
                                buttonGrey: false,
                                messageStepTwo: 'Uzupełnij wszystkie dane',
                              });

                              if (responseData.message.includes('JourneyId')) {
                                this.setState({
                                  messageStepTwoJourney:
                                    'Wybierz rodzaj transportu',
                                });
                              } else {
                                this.setState({
                                  messageStepTwoJourney: '',
                                });
                              }

                              if (responseData.message.includes('RoomId')) {
                                this.setState({
                                  messageStepTwoRoom:
                                    'Wybierz rodzaj zakwaterowania dla wszystkich uczestników',
                                });
                              } else {
                                this.setState({
                                  messageStepTwoRoom: '',
                                });
                              }

                              // if (responseData.message.includes("Journey")) {
                              //     this.setState({
                              //         messageStepTwoRoom: "Wybierz rodzaj zakwaterowania",
                              //         messageStepTwoJourney: ""
                              //     })
                              // }
                            }
                          });
                        });
                      } else {
                        this.setState({
                          buttonGrey: false,
                        });
                      }
                    } else {
                      this.setState({
                        buttonGrey: false,
                      });
                    }
                  });
                });
              } else {
                this.setState({
                  buttonGrey: false,
                });
              }
            });
          });
        } else {
          this.setState({
            buttonGrey: false,
          });
        }
      });
    });
  };

  handleUpdateStepTwoSingleReservation = () => {
    this.setState({
      buttonAvailableStepTwo: false,
      infoReserveList: '',
    });

    if (this.state.accommodationConfig[0].roomId === '') {
      this.setState({
        messageStepTwoRoom: 'Wybierz jednostkę',
        colorRoom: '#de1212',
        messageStepTwoRoom2: "Uzupełnij sekcję 'Rodzaj jednostki'",
        buttonAvailableStepTwo: true,
      });
    } else if (this.state.accommodationConfig[0].roomId !== '') {
      this.setState({
        messageStepTwoRoom: '',
        colorRoom: '',
        messageStepTwoRoom2: '',
        buttonAvailableStepTwo: true,
      });
    }

    if (this.state.accommodationConfig[0].journeyId === '') {
      this.setState({
        messageStepTwoJourney: 'Wybierz środek transportu',
        colorJourney: '#de1212',
        messageStepTwoJourney2: "Uzupełnij sekcję 'Dojazd' ",
        buttonAvailableStepTwo: true,
      });
    } else if (this.state.accommodationConfig[0].journeyId !== '') {
      this.setState({
        messageStepTwoJourney: '',
        colorJourney: '',
        messageStepTwoJourney2: '',
        buttonAvailableStepTwo: true,
      });
    }

    if (
      this.state.accommodationConfig[0].journeyPointId === '' &&
      !this.state.accommodationConfig[0].journeyId.includes('Dojazd')
    ) {
      this.setState({
        messageStepTwoJourneyPointId: 'Wybierz miejsce dosiadki',
        colorJourneyPoinId: '2px solid #de1212',
        messageStepTwoJourneyPointId2: "Uzupełnij sekcję 'Dojazd' ",
        buttonAvailableStepTwo: true,
      });
    } else if (
      this.state.accommodationConfig[0].journeyPointId === '' &&
      this.state.accommodationConfig[0].journeyId.includes('Dojazd')
    ) {
      this.setState({
        messageStepTwoJourneyPointId: '',
        colorJourneyPoinId: '',
        messageStepTwoJourneyPointId2: '',
        buttonAvailableStepTwo: true,
      });
    }

    if (
      this.state.accommodationConfig[0].journeyId !== '' &&
      this.state.accommodationConfig[0].roomId !== ''
    ) {
      const StepTwoArr = this.state.Reservation.Data.find(
        (el) => el.Step === 2
      );
      const accommodationArray = StepTwoArr.ResortsList.map((el) =>
        el.RoomsList.map((el) => el)
      );
      const updatedAccommodationArray =
        accommodationArray[4] !== undefined
          ? accommodationArray[0].concat(
              accommodationArray[1],
              accommodationArray[2],
              accommodationArray[3],
              accommodationArray[4]
            )
          : accommodationArray[3] !== undefined
          ? accommodationArray[0].concat(
              accommodationArray[1],
              accommodationArray[2],
              accommodationArray[3]
            )
          : accommodationArray[2] !== undefined
          ? accommodationArray[0].concat(
              accommodationArray[1],
              accommodationArray[2]
            )
          : accommodationArray[1] !== undefined
          ? accommodationArray[0].concat(accommodationArray[1])
          : accommodationArray[0];
      const updatedAccommodationArray2 = updatedAccommodationArray.map(
        (el) => ({
          ...el,
          canAvailablePlace:
            el.Availability.RoomsAvailableForReservationCount *
              el.Availability.AccommodationsCount >
              el.Availability.ReservedPlacesCount &&
            el.Availability.RoomsAvailableForReservationCount *
              el.Availability.AccommodationsCount !==
              el.Availability.ReservedPlacesCount,
        })
      );

      // const countFreePlaces = updatedAccommodationArray2.filter(el => (this.state.accommodationConfig[0].roomId === el.RoomId));
      // const countFreePlaces2 = ((countFreePlaces[0].Availability?.RoomsAvailableForReservationCount * countFreePlaces[0].Availability?.AccommodationsCount) - countFreePlaces[0].Availability?.ReservedPlacesCount)

      fetch(`${APIbackend}/v1/reservations/accommodations/update`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          siteId: this.state.siteId,
          reservationId: this.state.reservationId,
          accommodationConfig: this.state.accommodationConfig.map((el) => ({
            userNum: el.userNum,
            roomId:
              el.sameAsTheFirst === true ||
              updatedAccommodationArray2.some(
                (el) =>
                  this.state.accommodationConfig[0].roomId === el.RoomId &&
                  !el.canAvailablePlace
              )
                ? this.state.accommodationConfig[0].roomId
                : el.roomId,
            optionsChosen: [
              {
                bedroomChosen:
                  this.state.accommodationOption === 'cabin'
                    ? true
                    : el.bedroomChosen,
              },
            ],
          })),
          journeyConfig: this.state.accommodationConfig.map((el) => ({
            userNum: el.userNum,
            journeyId:
              el.sameAsTheFirst === true
                ? this.state.accommodationConfig[0].journeyId
                : el.journeyId,
            journeyPointId:
              el.sameAsTheFirst === true
                ? this.state.accommodationConfig[0].journeyPointId
                : el.journeyId !== '1'
                ? ''
                : el.journeyPointId,
          })),
          isReserveList:
            updatedAccommodationArray2.some(
              (el) =>
                this.state.accommodationConfig[0].roomId === el.RoomId &&
                !el.canAvailablePlace
            ) ||
            updatedAccommodationArray2.some(
              (el) =>
                this.state.accommodationConfig[0].roomId === el.RoomId &&
                el.Availability.RoomsAvailableForReservationCount *
                  el.Availability.AccommodationsCount >
                  el.Availability.ReservedPlacesCount &&
                el.Availability.RoomsAvailableForReservationCount *
                  el.Availability.AccommodationsCount !==
                  el.Availability.ReservedPlacesCount &&
                this.state.Reservation.Data[0].Config.ParticipantsCount >
                  el?.Availability?.RoomsAvailableForReservationCount *
                    el?.Availability?.AccommodationsCount -
                    el?.Availability?.ReservedPlacesCount
            )
              ? 1
              : 0,
        }),
      }).then((response) => {
        response.json().then((responseData) => {
          if (response.status !== 200) {
            this.setState({
              buttonAvailableStepTwo: true,
            });
            this.setState({
              stepTwo: true,
              messageStepTwo: 'Uzupełnij wszystkie dane',
            });

            if (responseData.message.includes('JourneyId')) {
              this.setState({
                messageStepTwoJourney:
                  'Wybierz rodzaj transportu dla wszystkich uczestników',
                colorJourney: '#de1212',
              });
            } else {
              this.setState({
                messageStepTwoJourney: '',
                colorJourney: '',
              });
            }

            if (responseData.message.includes('bedroom')) {
              this.setState({
                messageStepTwo:
                  'Rezerwacja kabiny dla wybranego jachtu jest niedostępna',
              });
            } else {
              this.setState({
                messageStepTwo: '',
              });
            }

            if (
              responseData.message.includes('RoomId') &&
              this.state.participantsCount > 1
            ) {
              this.setState({
                messageStepTwoRoom:
                  "Wybierz rodzaj zakwaterowania dla każdego uczestnika z osobna lub zaznacz 'Tak samo jak 1. uczestnik' jeżeli chcesz aby uczestnicy byli zakwaterowani w tym samym obiekcie",
                colorRoom: '#de1212',
              });
            } else if (
              responseData.message.includes('RoomId') &&
              this.state.participantsCount === 1
            ) {
              this.setState({
                messageStepTwoRoom: 'Wybierz rodzaj zakwaterowania',
                colorRoom: '#de1212',
              });
            } else {
              this.setState({
                messageStepTwoRoom: '',
                colorRoom: '',
              });
            }
          }
          if (response.status === 200) {
            this.setState({
              buttonAvailableStepTwo: false,
            });
            fetch(`${APIbackend}/v1/reservations/insurance/get`, {
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify({
                siteId: this.state.siteId,
                reservationId: this.state.reservationId,
              }),
            }).then((response) => {
              response.json().then((data) => {
                const pCount = data.Reservation.Data.find(
                  (el) => el.Step === 1
                );
                const participant = pCount.Config.ParticipantsCount + 1;
                this.setState({
                  insuranceConfig: [],
                });

                for (var x = 1; x < participant; x++) {
                  this.state.insuranceConfig.push({
                    id: x,
                    userNum: x,
                    valueSurname: '',
                    valueName: '',
                    resignationInsuranceAccepted: '0',
                    quarantineAssistanceAccepted: '0',
                    sameAsTheFirst: true,
                    insuranceId: '1',
                    firstName: '',
                    lastName: '',
                  });
                }
                this.setState({
                  stepTwo: false,
                  stepThree: true,
                  stepNumberTwo: true,
                  Reservation: data.Reservation,
                  insuranceConfig: this.state.insuranceConfig,
                  messageStepTwo: '',
                  buttonAvailableStepTwo: true,
                });
              });
            });
          }
        });
      });

      // }
    }
  };

  handleUpdateStepThree = () => {
    this.setState({
      buttonAvailableStepThree: false,
    });

    fetch(`${APIbackend}/v1/reservations/insurance/update`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        siteId: this.state.siteId,
        reservationId: this.state.reservationId,
        insuranceConfig: this.state.insuranceConfig.map((el) => ({
          userNum: el.userNum,
          resignationInsuranceAccepted:
            el.sameAsTheFirst === true
              ? this.state.insuranceConfig[0].resignationInsuranceAccepted
              : el.resignationInsuranceAccepted,
          insuranceId:
            el.sameAsTheFirst === true
              ? this.state.insuranceConfig[0].insuranceId
              : el.insuranceId,
          quarantineAssistanceAccepted:
            el.sameAsTheFirst === true
              ? this.state.insuranceConfig[0].quarantineAssistanceAccepted
              : el.quarantineAssistanceAccepted,
        })),
      }),
    }).then((response) => {
      response.json().then(() => {
        if (response.status === 200) {
          this.setState({
            buttonAvailableStepThree: false,
          });
          fetch(`${APIbackend}/v1/reservations/addons/get`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
              siteId: this.state.siteId,
              reservationId: this.state.reservationId,
            }),
          }).then((response) => {
            response.json().then((data) => {
              const pCount = data.Reservation.Data.find((el) => el.Step === 1);
              const participant = pCount.Config.ParticipantsCount + 1;
              this.setState({
                trainingsConfig: [],
              });

              for (var z = 1; z < participant; z++) {
                this.state.trainingsConfig.push({
                  userNum: z,
                  valueSurname: '',
                  valueName: '',
                  trainingsSelected: {
                    id: z,
                    trainingId: '',
                    trainingOptionId: '',
                  },
                  addonsSelected: {
                    id: z,
                    addonId: '',
                    addonOptionId: '',
                  },
                  // {
                  //     id: z + 1,
                  //     addonId: "",
                  //     addonOptionId: ""
                  // },
                  // {
                  //     id: z + 2,
                  //     addonId: "",
                  //     addonOptionId: ""
                  // }
                });
              }

              this.setState({
                stepThree: false,
                stepFour: true,
                stepNumberThree: true,
                Reservation: data.Reservation,
                trainingsConfig: this.state.trainingsConfig,
                addonsConfig: this.state.addonsConfig,
                buttonAvailableStepThree: true,
              });
            });
          });
        }
        if (response.status === 400) {
          this.setState({
            stepThree: true,
            messageStepThree: 'Uzupełnij wszystkie dane',
            buttonAvailableStepThree: true,
          });
        }
      });
    });
  };

  handleUpdateStepFour = () => {
    const stepFourArrLuggage = this.state.Reservation.Data[3].AddonsList.map(
      (el) => el.AddonId
    );
    const stepFourArrLuggage2 = stepFourArrLuggage.some((el) => el === '7');

    this.setState({
      buttonAvailableStepFour: false,
    });

    if (
      this.state.trainingsConfig[0].addonsSelected.addonId !== '' &&
      this.state.trainingsConfig[0].addonsSelected.addonOptionId === '0'
    ) {
      this.setState({
        messageSizeAddon: 'Wybierz rozmiar',
        buttonAvailableStepFour: true,
        messageStepFour: '',
      });
    } else if (
      this.state.trainingsConfig.some(
        (el) =>
          el.addonsSelected.addonOptionId === '0' ||
          el.addonsSelected.addonOptionId === ''
      ) &&
      this.state.addonsConfig.length > 0
    ) {
      this.setState({
        messageSizeAddon: '',
        buttonAvailableStepFour: true,
        messageStepFour: 'Wybierz rozmiar koszulki dla każdego uczestnika',
      });
    } else {
      this.setState({
        messageSizeAddon: '',
        buttonAvailableStepFour: false,
      });
      fetch(`${APIbackend}/v1/reservations/addons/update`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          siteId: this.state.siteId,
          reservationId: this.state.reservationId,
          trainingsConfig: this.state.trainingsConfig.map((el) => ({
            userNum: el.userNum,
            // trainingsSelected:
            //     el.trainingsSelected.trainingId !== "" ? [{
            //         trainingId: el.trainingsSelected.trainingId,
            //         trainingOptionId: el.trainingsSelected.trainingId === "" ? "" : el.trainingsSelected.trainingOptionId
            //     }] : []
            trainingsSelected: [],
          })),

          addonsConfig: this.state.trainingsConfig.map((el) => ({
            userNum: el.userNum,
            addonsSelected:
              this.state.accommodationConfig[el.userNum - 1]
                .transportAddLuggage === true &&
              el.addonsSelected.addonId === '' &&
              stepFourArrLuggage2
                ? [
                    {
                      addonId: '7',
                      addonOptionId: '13',
                    },
                  ]
                : this.state.accommodationConfig[el.userNum - 1]
                    .transportAddLuggage === true &&
                  el.addonsSelected.addonId !== '' &&
                  stepFourArrLuggage2
                ? [
                    {
                      addonId: '7',
                      addonOptionId: '13',
                    },
                    {
                      addonId: el.addonsSelected.addonId,
                      addonOptionId:
                        el.addonsSelected.addonId === ''
                          ? ''
                          : el.addonsSelected.addonOptionId,
                    },
                  ]
                : this.state.accommodationConfig[el.userNum - 1]
                    .transportAddLuggage === false &&
                  el.addonsSelected.addonId !== ''
                ? [
                    {
                      addonId: el.addonsSelected.addonId,
                      addonOptionId:
                        el.addonsSelected.addonId === ''
                          ? ''
                          : el.addonsSelected.addonOptionId,
                    },
                  ]
                : [],

            // [{
            //     addonId: el.addonsSelected.addonId[0],
            //     addonOptionId: el.addonsSelected.addonId === "" ? "" : el.addonsSelected.addonOptionId
            // },
            // {
            //     addonId: el.addonsSelected.addonId[1],
            //     addonOptionId: el.addonsSelected.addonId[1] === "" ? "" : el.addonsSelected.addonOptionId
            // }]
          })),
        }),
      }).then((response) => {
        response.json().then(() => {
          if (response.status === 200) {
            this.setState({
              buttonAvailableStepFour: false,
            });
            fetch(`${APIbackend}/v1/reservations/participants/get`, {
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify({
                siteId: this.state.siteId,
                reservationId: this.state.reservationId,
              }),
            }).then((response) => {
              response.json().then((data) => {
                const pCount = data.Reservation.Data.find(
                  (el) => el.Step === 1
                );
                const participant = pCount.Config.ParticipantsCount + 1;
                const StepFiveArr = data.Reservation.Data.find(
                  (el) => el.Step === 5
                );

                this.setState({
                  participantsConfig: [],
                });

                for (var p = 1; p < participant; p++) {
                  this.state.participantsConfig.push({
                    id: p,
                    userNum: p,
                    fillInLater:
                      p === 1
                        ? 0
                        : this.state.Reservation.Data[2].Config.InsuranceConfig[
                            p - 1
                          ].resignationInsuranceAccepted === '1'
                        ? false
                        : this.state.accommodationOption === 'cabin' ||
                          this.state.accommodationOption === 'single'
                        ? 0
                        : 1,
                    valueSurname: '',
                    wrongMessage:
                      p === 1
                        ? null
                        : p !== 1 &&
                          this.state.Reservation.Data[2].Config.InsuranceConfig[
                            p - 1
                          ].resignationInsuranceAccepted === '1'
                        ? 'Dla tego uczestnika wybrano ubezpieczenie od kosztów rezygnacji, w związku z tym wymagane jest podanie imienia, nazwiska i daty urodzenia (pozostałe dane możesz uzupełnić później).'
                        : null,
                    valueName: '',
                    password: '',
                    policyAccepted: 0,
                    newsletterAccepted: 0,
                    firstName:
                      p === 1 && StepFiveArr.Account !== null
                        ? StepFiveArr.Account.FirstName
                        : '',
                    lastName:
                      p === 1 && StepFiveArr.Account !== null
                        ? StepFiveArr.Account.LastName
                        : '',
                    country:
                      p === 1 &&
                      StepFiveArr.Account !== null &&
                      StepFiveArr.Account.Country === null
                        ? 'Polska'
                        : p === 1 && StepFiveArr.Account !== null
                        ? StepFiveArr.Account.Country
                        : 'Polska',
                    birthDate:
                      p === 1 && StepFiveArr.Account !== null
                        ? StepFiveArr.Account.BirthDate
                        : '',
                    idDocument:
                      p === 1 && StepFiveArr.Account !== null
                        ? StepFiveArr.Account.IdDocument
                        : '',
                    gender:
                      p === 1 && StepFiveArr.Account !== null
                        ? StepFiveArr.Account.Gender
                        : '',
                    phone:
                      p === 1 && StepFiveArr.Account !== null
                        ? StepFiveArr.Account.Phone
                        : '',
                    email:
                      p === 1 && StepFiveArr.Account !== null
                        ? StepFiveArr.Account.Email
                        : '',
                    // haveAVoucher: p === 1 ? true : false,
                    haveAVoucher: false,
                  });
                }

                this.setState({
                  stepFour: false,
                  stepFive: true,
                  stepNumberFour: true,
                  participantsConfig: this.state.participantsConfig,
                  Reservation: data.Reservation,
                  buttonAvailableStepFour: true,
                });
              });
            });
          }
          if (response.status === 400) {
            this.setState({
              stepFour: true,
              messageStepFour: 'Uzupełnij wszystkie dane',
              buttonAvailableStepFour: true,
            });
          }
        });
      });
    }
  };

  handleGoToStepFive = () => {
    const stepFourArrLuggage = this.state.Reservation.Data[3].AddonsList.map(
      (el) => el.AddonId
    );
    const stepFourArrLuggage2 = stepFourArrLuggage.some((el) => el === '7');

    this.setState({
      buttonAvailableStepFour: false,
    });
    fetch(`${APIbackend}/v1/reservations/addons/update`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        siteId: this.state.siteId,
        reservationId: this.state.reservationId,
        trainingsConfig: this.state.trainingsConfig.map((el) => ({
          userNum: el.userNum,
          trainingsSelected: [],
        })),

        addonsConfig: this.state.trainingsConfig.map((el) => ({
          userNum: el.userNum,
          addonsSelected:
            this.state.accommodationConfig[el.userNum - 1]
              .transportAddLuggage === true && stepFourArrLuggage2
              ? [
                  {
                    addonId: '7',
                    addonOptionId: '13',
                  },
                ]
              : [],
        })),
      }),
    }).then((response) => {
      response.json().then(() => {
        fetch(`${APIbackend}/v1/reservations/participants/get`, {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({
            siteId: this.state.siteId,
            reservationId: this.state.reservationId,
          }),
        }).then((response) => {
          response.json().then((data) => {
            const pCount = data.Reservation.Data.find((el) => el.Step === 1);
            const participant = pCount.Config.ParticipantsCount + 1;
            const StepFiveArr = data.Reservation.Data.find(
              (el) => el.Step === 5
            );

            this.setState({
              participantsConfig: [],
            });
            for (var p = 1; p < participant; p++) {
              this.state.participantsConfig.push({
                id: p,
                userNum: p,
                valueSurname: '',
                wrongMessage:
                  p === 1
                    ? null
                    : p !== 1 &&
                      this.state.Reservation.Data[2].Config.InsuranceConfig[
                        p - 1
                      ].resignationInsuranceAccepted === '1'
                    ? 'Dla tego uczestnika wybrano ubezpieczenie od kosztów rezygnacji, w związku z tym wymagane jest podanie imienia, nazwiska i daty urodzenia (pozostałe dane możesz uzupełnić później).'
                    : null,
                valueName: '',
                fillInLater:
                  p === 1
                    ? 0
                    : this.state.Reservation.Data[2].Config.InsuranceConfig[
                        p - 1
                      ].resignationInsuranceAccepted === '1'
                    ? false
                    : this.state.accommodationOption === 'cabin' ||
                      this.state.accommodationOption === 'single'
                    ? 0
                    : 1,
                password: '',
                policyAccepted: 0,
                newsletterAccepted: 0,
                firstName:
                  p === 1 && StepFiveArr.Account !== null
                    ? StepFiveArr.Account.FirstName
                    : '',
                lastName:
                  p === 1 && StepFiveArr.Account !== null
                    ? StepFiveArr.Account.LastName
                    : '',
                country:
                  p === 1 &&
                  StepFiveArr.Account !== null &&
                  StepFiveArr.Account.Country === null
                    ? 'Polska'
                    : p === 1 && StepFiveArr.Account !== null
                    ? StepFiveArr.Account.Country
                    : 'Polska',
                birthDate:
                  p === 1 && StepFiveArr.Account !== null
                    ? StepFiveArr.Account.BirthDate
                    : '',
                idDocument:
                  p === 1 && StepFiveArr.Account !== null
                    ? StepFiveArr.Account.IdDocument
                    : '',
                gender:
                  p === 1 && StepFiveArr.Account !== null
                    ? StepFiveArr.Account.Gender
                    : '',
                phone:
                  p === 1 && StepFiveArr.Account !== null
                    ? StepFiveArr.Account.Phone
                    : '',
                email:
                  p === 1 && StepFiveArr.Account !== null
                    ? StepFiveArr.Account.Email
                    : '',
                // haveAVoucher: p === 1 ? true : false,
                haveAVoucher: false,
              });
            }

            this.setState({
              stepFour: false,
              stepFive: true,
              stepNumberFour: true,
              participantsConfig: this.state.participantsConfig,
              Reservation: data.Reservation,
              buttonAvailableStepFour: true,
            });
          });
        });
      });
    });
  };

  handleUpdateStepFive = () => {
    this.setState({
      buttonAvailableStepFive: false,
    });
    if (
      (this.state.accommodationOption === 'single' ||
        this.state.accommodationOption === 'cabin') &&
      this.state.participantsConfig.some((el) =>
        el.birthDate.slice(0, 4).includes('-')
      )
    ) {
      this.setState({
        messageStepFive:
          'Data urodzenia ma nieprawidłowy format. Wprowadź datę w poprawnym fromacie: RRRR-MM-DD',
        stepFive: true,
        buttonAvailableStepFive: true,
      });
    } else if (
      !this.state.Reservation.Offer.Name.includes('Akademia') &&
      this.state.typeId !== '11' &&
      this.state.typeId !== '12' &&
      (this.state.accommodationOption === 'single' ||
        this.state.accommodationOption === 'cabin') &&
      this.state.participantsConfig.some(
        (el) =>
          new Date(
            new Date().setFullYear(new Date().getFullYear() - 45)
          ).getFullYear() >= parseInt(el.birthDate.slice(0, 4))
      )
    ) {
      this.setState({
        messageStepFive:
          'Prosimy o kontakt z biurem, celem weryfikacji czy na rejsie będzie załoga w wieku 45+',
        stepFive: true,
        buttonAvailableStepFive: true,
      });
    } else if (
      this.state.typeId !== '11' &&
      this.state.typeId !== '12' &&
      (this.state.accommodationOption === 'single' ||
        this.state.accommodationOption === 'cabin') &&
      this.state.participantsConfig.some(
        (el) =>
          new Date(
            new Date().setFullYear(new Date().getFullYear() - 18)
          ).getFullYear() < parseInt(el.birthDate.slice(0, 4))
      )
    ) {
      this.setState({
        messageStepFive: 'Osoby niepełnoletnie nie mogą uczestniczyć w rejsie',
        stepFive: true,
        buttonAvailableStepFive: true,
      });
    } else {
      fetch(`${APIbackend}/v1/reservations/participants/update`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          siteId: this.state.siteId,
          reservationId: this.state.reservationId,
          participantsConfig: this.state.participantsConfig.map((el) => ({
            userNum: el.userNum,
            fillInLater: el.fillInLater,
            firstName: el.fillInLater === 1 ? '' : el.firstName,
            password: el.password,
            policyAccepted: el.policyAccepted,
            newsletterAccepted: el.newsletterAccepted,
            country: el.fillInLater === 1 ? '' : el.country,
            lastName: el.fillInLater === 1 ? '' : el.lastName,
            birthDate: el.fillInLater === 1 ? '' : el.birthDate,
            idDocument: el.fillInLater === 1 ? '' : el.idDocument,
            gender: el.fillInLater === 1 ? '' : el.gender,
            phone: el.fillInLater === 1 ? '' : el.phone,
            email: el.email,
          })),
        }),
      }).then((response) => {
        response.json().then((responseData) => {
          if (response.status === 200) {
            this.setState({
              message: '',
              messageStepFive: '',
              stepFive: false,
              buttonAvailableStepFive: false,
            });

            fetch(`${APIbackend}/v1/reservations/confirmation/get`, {
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify({
                siteId: this.state.siteId,
                reservationId: this.state.reservationId,
              }),
            }).then((response) => {
              response.json().then((data) => {
                this.setState({
                  stepSix: true,
                  stepFour: false,
                  stepFive: false,
                  stepNumberFive: true,
                  Reservation: data.Reservation,
                  buttonAvailableStepFive: true,
                  paymentType:
                    data.Reservation.Data[2].Config.InsuranceConfig.some(
                      (el) => el.resignationInsuranceAccepted === '1'
                    )
                      ? 'payu'
                      : 'wired',
                });
              });
            });

            this.state.beSkipper === 'TAK' &&
              fetch(`${APIbackend}/v1/rebates/ambassador/use`, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                  siteId: this.state.siteId,
                  reservationId: this.state.reservationId,
                  ambassadorCode: 'jestemSkipperemTBT',
                }),
              }).then((response) => {
                response.json().then(() => {
                  if (response.status === 200) {
                    this.setState({
                      messageAmbassador:
                        '✔ Rabat został naliczony do rezerwacji',
                      colorMessageAmb: 'green',
                      codeAmbassadorFormVisible: false,
                      messageAmbassadorVisible: true,
                    });
                    fetch(`${APIbackend}/v1/reservations/confirmation/get`, {
                      method: 'POST',
                      credentials: 'include',
                      body: JSON.stringify({
                        siteId: this.state.siteId,
                        reservationId: this.state.reservationId,
                      }),
                    }).then((response) => {
                      response.json().then((data) => {
                        this.setState({
                          Reservation: data.Reservation,
                        });
                      });
                    });
                  }

                  if (response.status !== 200) {
                    this.setState({
                      messageAmbassador:
                        'Kod Ambasadora jest nieprawidłowy lub został już przypisany do rezerwacji',
                      colorMessageAmb: 'red',
                    });
                  }
                });
              });
          }

          if (response.status === 400) {
            this.setState({
              stepFive: true,
              messageStepFive:
                'Należy podać imię, nazwisko i datę urodzenia każdego uczestnika.',
              buttonAvailableStepFive: true,
            });

            if (
              responseData.message.includes('firstName') &&
              responseData.message.includes('userNum #1')
            ) {
              this.setState({
                colorInputRedFirstName: 'colorInputRed',
                messageStepFive:
                  this.state.messageStepFive +
                  'Pole w którym należy podać imie jest puste. \n',
                buttonAvailableStepFive: true,
              });
            } else {
              this.setState({
                colorInputRedFirstName: '',
              });
            }
            if (
              (responseData.message.includes('phone') &&
                responseData.message.includes('userNum #1')) ||
              this.state.validatePhone === false
            ) {
              this.setState({
                colorInputRedPhone: 'colorInputRed',
                messageStepFive:
                  this.state.messageStepFive + 'Niepoprawny numer telefonu. \n',
                buttonAvailableStepFive: true,
              });
            } else {
              this.setState({
                colorInputRedPhone: '',
              });
            }

            if (
              responseData.message.includes('lastName') &&
              responseData.message.includes('userNum #1')
            ) {
              this.setState({
                colorInputRedLastName: 'colorInputRed',
                messageStepFive:
                  this.state.messageStepFive +
                  'Pole w którym należy podać nazwisko jest puste. \n',
                buttonAvailableStepFive: true,
              });
            } else {
              this.setState({
                colorInputRedLastName: '',
              });
            }

            if (
              responseData.message.includes('birthDate') &&
              responseData.message.includes('userNum #1')
            ) {
              this.setState({
                colorInputRedBirthDate: 'colorInputRed',
                messageStepFive:
                  this.state.messageStepFive +
                  'Niepoprawny format daty urodzenia.\n',
                buttonAvailableStepFive: true,
              });
            } else {
              this.setState({
                colorInputRedBirthDate: '',
              });
            }

            if (
              responseData.message.includes('idDocument') &&
              responseData.message.includes('userNum #1')
            ) {
              this.setState({
                colorInputRedIdDocument: 'colorInputRed',
                messageStepFive:
                  this.state.messageStepFive +
                  'Nieprawidłowy numer dokumentu.\n',
                buttonAvailableStepFive: true,
              });
            } else {
              this.setState({
                colorInputRedIdDocument: '',
              });
            }

            if (
              responseData.message.includes('birthDate') &&
              responseData.message.includes('userNum #2')
            ) {
              this.setState({
                messageStepFive:
                  this.state.messageStepFive +
                  '<br/>Drugi uczestnik: Data urodzenia ma niepoprawny format',
              });
            }

            if (
              responseData.message.includes('idDocument') &&
              responseData.message.includes('userNum #2')
            ) {
              this.setState({
                messageStepFive:
                  this.state.messageStepFive +
                  '<br/>Drugi uczestnik: Seria i numer dowodu osobistego ma niepoprawny format',
              });
            }

            if (
              responseData.message.includes('password') &&
              responseData.message.includes('userNum #1')
            ) {
              this.setState({
                colorInputRedPassword: 'colorInputRed',
              });
            } else {
              this.setState({
                colorInputRedPassword: '',
              });
            }

            if (responseData.message.includes('exists')) {
              this.setState({
                messageStepFive:
                  '<br/>Użytkownik o podanym adresie email jest już w naszym systemie',
              });
            } else {
              this.setState({});
            }

            if (responseData.message.includes('creating')) {
              this.setState({
                colorInputRedPolicyAcc: '',
                messageStepFive:
                  'Sprawdź, czy prawidłowo uzupełniłeś wszystkie dane potrzebne do utworzenia konta: hasło, email, akceptacja regulaminu.',
              });
            } else {
              this.setState({
                colorInputRedPolicyAcc: '',
              });
            }

            if (
              responseData.message.includes('email') &&
              responseData.message.includes('userNum #1')
            ) {
              this.setState({
                colorInputRedEmail: 'colorInputRed',
              });
            } else {
              this.setState({
                colorInputRedEmail: '',
              });
            }
          } else {
            this.setState({
              messageStepFive: '',
            });
          }
        });
      });
    }
  };

  handleUpdateStepSix = () => {
    this.setState({
      buttonAvailableStepSix: false,
    });

    fetch(`${APIbackend}/v1/reservations/confirm`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        siteId: this.state.siteId,
        reservationId: this.state.reservationId,
        comments:
          this.state.skipper === 'TAK' &&
          this.state.accommodationOption === 'whole'
            ? 'Mamy swojego skippera. ' + this.state.comments
            : this.state.skipper === 'NIE' &&
              this.state.accommodationOption === 'whole'
            ? 'Chcemy skippera od TBT. ' + this.state.comments
            : this.state.comments,
        paymentOption: this.state.paymentOption,
        paymentType: this.state.paymentType,
        termsAccepted: this.state.termsAccepted,
      }),
    }).then((response) => {
      response.json().then(() => {
        const payUUrl = this.state.Reservation.Data[5].PaymentUrl;

        if (response.status !== 200) {
          this.setState({
            messageStepSix: 'Uzupełnij wszystkie dane',
            buttonAvailableStepSix: true,
          });
        }

        if (response.status === 200 && this.state.paymentType === 'wired') {
          this.setState({
            stepNumberSix: true,
            stepSix: false,
            stepSubmit: true,
            messageStepSix: '',
            buttonAvailableStepSix: false,
          });
        }

        if (response.status === 200 && this.state.paymentType === 'payu') {
          this.setState({
            redirectToPayU: true,
            paymentLink: payUUrl.slice(1, payUUrl.length),
            stepSubmit: false,
            stepSix: false,
            buttonAvailableStepSix: false,
          });
        }
      });
    });
  };

  render() {
    const {
      handleAddName,
      handleSaveName,
      handleEditName,
      handleCheckboxChange,
      handleBackToStepOne,
      showCreateCode,
      handleUpdateStepTwoSingleReservation,
      handleGetStepOne,
      handleClickLess,
      handleClickMore,
      handleChange,
      handleUpdateStepTwoWholeYacht,
      handleChange3,
      handleChange4,
      handleChange5,
      handleChange6,
      handleAddGroupCode,
      handleUpdateStepOne,
      handleCreateGroupCode,
    } = this;
    const {
      stepNumberOne,
      stepNumberTwo,
      stepNumberThree,
      stepNumberFour,
      stepNumberFive,
      inputAddNameActive,
      colorInputRedPolicyAcc,
      sameAsTheFirst,
      addOptionsArr,
      addOptions,
      transport,
      roomId,
      yachtClass,
      stepTwo,
      stepThree,
      stepFour,
      stepFive,
      stepSix,
      Reservation,
      termsAccepted,
      errorMessage,
      errorMessage2,
      secondTrueMessage,
      stepNumberSix,
      colorInputRedPassword,
      trueMessage,
      reservationId,
      stepOne,
      participantsCount,
      accommodationOption,
      groupCode,
      colorInputRedGender,
      colorInputRedLastName,
      groupCodeCreate,
      groupRebateAvailable,
      accommodationConfig,
      stepSubmit,
      colorInputRedPhone,
      colorInputRedFirstName,
      colorInputRedEmail,
      colorInputRedCountry,
      colorInputRedIdDocument,
      colorInputRedBirthDate,
    } = this.state;

    return (
      <>
        <Router>
          <Switch>
            {stepOne ? <Redirect to="/rezerwacja/twoj-rejs" /> : null}
            {stepOne && (
              <Route path="/rezerwacja/twoj-rejs">
                <StepOne
                  {...this.props}
                  infoReserveList={this.state.infoReserveList}
                  beSkipper={this.state.beSkipper}
                  buttonAvailableStepOne={this.state.buttonAvailableStepOne}
                  buttonGenerateCodeVisible={
                    this.state.buttonGenerateCodeVisible
                  }
                  trueMessageVisible={this.state.trueMessageVisible}
                  addGrupCodeVisible={this.state.addGrupCodeVisible}
                  displayNone={this.state.displayNone}
                  userLogged={this.props.userLogged}
                  handleChangeParticipants={this.handleChangeParticipants}
                  skipper={this.state.skipper}
                  handleClickMore1={this.handleClickMore1}
                  handleClickLess1={this.handleClickLess1}
                  participantsCountWhole={this.state.participantsCountWhole}
                  reservationId={reservationId}
                  showCreateCode={showCreateCode}
                  Reservation={Reservation}
                  messageStepOne={this.state.messageStepOne}
                  termsAccepted={termsAccepted}
                  trueMessage={trueMessage}
                  errorMessage={errorMessage}
                  secondTrueMessage={secondTrueMessage}
                  errorMessage2={errorMessage2}
                  handleClickLess={handleClickLess}
                  accommodationOption={accommodationOption}
                  participantsCount={participantsCount}
                  groupRebateAvailable={groupRebateAvailable}
                  handleAddGroupCode={handleAddGroupCode}
                  groupCode={groupCode}
                  handleCreateGroupCode={handleCreateGroupCode}
                  render={this.state.render}
                  groupCodeCreate={groupCodeCreate}
                  handleClickMore={handleClickMore}
                  handleChange={handleChange}
                  handleGetStepOne={handleGetStepOne}
                  handleUpdateStepOne={handleUpdateStepOne}
                  handleChange3={handleChange3}
                />
              </Route>
            )}

            {stepTwo ? (
              <Redirect to="/rezerwacja/zakwaterowanie-i-dojazd" />
            ) : null}
            {stepTwo && (
              <Route path={'/rezerwacja/zakwaterowanie-i-dojazd'}>
                <StepTwo
                  {...this.props}
                  reserveListJourneyOption={this.state.reserveListJourneyOption}
                  infoReserveList={this.state.infoReserveList}
                  handleChangeAddTransport={this.handleChangeAddTransport}
                  colorJourneyPoinId={this.state.colorJourneyPoinId}
                  messageStepTwoJourneyPointId2={
                    this.state.messageStepTwoJourneyPointId2
                  }
                  messageStepTwoJourneyPointId={
                    this.state.messageStepTwoJourneyPointId
                  }
                  buttonAvailableStepTwo={this.state.buttonAvailableStepTwo}
                  handleEditNameWhole={this.handleEditNameWhole}
                  handleEditNameSingle={this.handleEditNameSingle}
                  handleEditNameSingleReservation={
                    this.handleEditNameSingleReservation
                  }
                  handleEditNameSingleReservation2={
                    this.handleEditNameSingleReservation2
                  }
                  handleEditNameWholeReservation={
                    this.handleEditNameWholeReservation
                  }
                  handleEditNameWholeReservation2={
                    this.handleEditNameWholeReservation2
                  }
                  accommodationConfigNew={this.state.accommodationConfigNew}
                  handleChangePointId={this.handleChangePointId}
                  buttonGrey={this.state.buttonGrey}
                  colorRoom={this.state.colorRoom}
                  colorJourney={this.state.colorJourney}
                  messageStepTwoRoom2={this.state.messageStepTwoRoom2}
                  stepName={this.state.stepName}
                  handleChangeJourneyWholeYacht={
                    this.handleChangeJourneyWholeYacht
                  }
                  messageStepTwoJourney2={this.state.messageStepTwoJourney2}
                  messageStepTwoJourney={this.state.messageStepTwoJourney}
                  messageStepTwoRoom={this.state.messageStepTwoRoom}
                  handleChangeParticipantWholeYacht={
                    this.handleChangeParticipantWholeYacht
                  }
                  journeyId={this.state.journeyId}
                  messageStepTwo={this.state.messageStepTwo}
                  reservationId={reservationId}
                  Reservation={Reservation}
                  handleAddName={handleAddName}
                  inputAddNameActive={inputAddNameActive}
                  handleSaveName={handleSaveName}
                  handleEditName1={this.handleEditName1}
                  handleEditName2={this.handleEditName2}
                  handleChange={handleChange}
                  handleEditName={handleEditName}
                  yachtClass={yachtClass}
                  roomId={roomId}
                  addOptionsArr={addOptionsArr}
                  addOptions={addOptions}
                  transport={transport}
                  sameAsTheFirst={sameAsTheFirst}
                  handleChange27={this.handleChange27}
                  participantsCount={participantsCount}
                  message={this.state.message}
                  skipper={this.state.skipper}
                  handleChange14={this.handleChange14}
                  accommodationOption={accommodationOption}
                  handleUpdateStepTwoSingleReservation={
                    handleUpdateStepTwoSingleReservation
                  }
                  handleBackToStepOne={handleBackToStepOne}
                  handleCheckboxChange={handleCheckboxChange}
                  accommodationConfig={accommodationConfig}
                  handleUpdateStepTwoWholeYacht={handleUpdateStepTwoWholeYacht}
                  journeyPointId={this.state.journeyPointId}
                  handleChange4={handleChange4}
                  handleChange5={handleChange5}
                  handleChange6={handleChange6}
                  participantsCountWhole={this.state.participantsCountWhole}
                  messageStepTwoCabin={this.state.messageStepTwoCabin}
                  counternumber={this.state.count}
                />
              </Route>
            )}

            {stepThree ? <Redirect to="/rezerwacja/ubezpieczenie" /> : null}
            {stepThree && (
              <Route path="/rezerwacja/ubezpieczenie">
                <StepThree
                  {...this.props}
                  stepName={this.state.stepName}
                  buttonAvailableStepThree={this.state.buttonAvailableStepThree}
                  accommodationOption={accommodationOption}
                  accommodationConfigNew={this.state.accommodationConfigNew}
                  reservationId={reservationId}
                  messageStepThree={this.state.messageStepThree}
                  insurance={this.state.insurance}
                  sameAsTheFirst={sameAsTheFirst}
                  handleUpdateStepThree={this.handleUpdateStepThree}
                  handlebackToStepTwo={this.handlebackToStepTwo}
                  Reservation={Reservation}
                  insuranceConfig={this.state.insuranceConfig}
                  handleChange7={this.handleChange7}
                  handleChange8={this.handleChange8}
                  handleChange9={this.handleChange9}
                  handleAssistanceChange={this.handleAssistanceChange}
                  accommodationConfig={accommodationConfig}
                />
              </Route>
            )}

            {stepFour ? <Redirect to="/rezerwacja/dodatki" /> : null}
            {stepFour && (
              <Route path="/rezerwacja/dodatki">
                <StepFour
                  {...this.props}
                  messageSizeAddon={this.state.messageSizeAddon}
                  buttonAvailableStepFour={this.state.buttonAvailableStepFour}
                  accommodationOption={accommodationOption}
                  insuranceConfig={this.state.insuranceConfig}
                  stepName={this.state.stepName}
                  reservationId={reservationId}
                  accommodationConfig={accommodationConfig}
                  accommodationConfigNew={this.state.accommodationConfigNew}
                  messageStepFour={this.state.messageStepFour}
                  Reservation={Reservation}
                  handleChange10={this.handleChange10}
                  handleChange11={this.handleChange11}
                  handleChange12={this.handleChange12}
                  handleChange13={this.handleChange13}
                  handleUpdateStepFour={this.handleUpdateStepFour}
                  handleGoToStepFive={this.handleGoToStepFive}
                  handleBackToStepThree={this.handleBackToStepThree}
                  addonsConfig={this.state.addonsConfig}
                  trainingsConfig={this.state.trainingsConfig}
                />
              </Route>
            )}
            {stepFive ? <Redirect to="/rezerwacja/dane-uczestnikow" /> : null}
            {stepFive && (
              <Route path="/rezerwacja/dane-uczestnikow">
                <StepFive
                  {...this.props}
                  messageKR={this.state.messageKR}
                  buttonAvailableStepFive={this.state.buttonAvailableStepFive}
                  accommodationConfigNew={this.state.accommodationConfigNew}
                  accommodationOption={accommodationOption}
                  stepName={this.state.stepName}
                  insuranceConfig={this.state.insuranceConfig}
                  skipper={this.state.skipper}
                  countryList={this.state.countryList}
                  accommodationConfig={accommodationConfig}
                  handleValidationDate={this.handleValidationDate}
                  handleChange36={this.handleChange36}
                  handleClick={this.props.handleClick}
                  type={this.props.type}
                  colorInputRedAgree={this.state.colorInputRedAgree}
                  handleChange30={this.handleChange30}
                  handleChange15={this.handleChange15}
                  handleChange16={this.handleChange16}
                  handleChange17={this.handleChange17}
                  handleChange18={this.handleChange18}
                  handleChange19={this.handleChange19}
                  handleChange20={this.handleChange20}
                  handleChange21={this.handleChange21}
                  handleChange25={this.handleChange25}
                  handleChange22={this.handleChange22}
                  reservationId={reservationId}
                  Reservation={Reservation}
                  handleChange={handleChange}
                  messageStepFive={this.state.messageStepFive}
                  handleUpdateStepFive={this.handleUpdateStepFive}
                  handleBackToStepFour={this.handleBackToStepFour}
                  participantDetails={this.state.participantDetails}
                  detailsLater={this.state.detailsLater}
                  handleChange2={this.handleChange2}
                  list={this.state.list}
                  allChecked={this.state.allChecked}
                  colorInputRedPolicyAcc={colorInputRedPolicyAcc}
                  participantsConfig={this.state.participantsConfig}
                  message={this.state.message}
                  colorInputRedCountry={colorInputRedCountry}
                  colorInputRedPassword={colorInputRedPassword}
                  handleChange31={this.handleChange31}
                  colorInputRedPhone={colorInputRedPhone}
                  colorInputRedGender={colorInputRedGender}
                  colorInputRedFirstName={colorInputRedFirstName}
                  colorInputRedLastName={colorInputRedLastName}
                  colorInputRedBirthDate={colorInputRedBirthDate}
                  colorInputRedIdDocument={colorInputRedIdDocument}
                  colorInputRedEmail={colorInputRedEmail}
                />
              </Route>
            )}
            {stepSix ? (
              <Redirect to="/rezerwacja/podsumowanie-i-platnosc" />
            ) : null}
            {stepSix && (
              <Route path="/rezerwacja/podsumowanie-i-platnosc">
                <StepSix
                  {...this.props}
                  handleSubmitAmbassadorSkipper={
                    this.handleSubmitAmbassadorSkipper
                  }
                  beSkipper={this.state.beSkipper}
                  buttonAvailableStepSix={this.state.buttonAvailableStepSix}
                  buttonGenerateCodeVisible={
                    this.state.buttonGenerateCodeVisible
                  }
                  trueMessageVisible={this.state.trueMessageVisible}
                  addGrupCodeVisible={this.state.addGrupCodeVisible}
                  codeAmbassadorFormVisible={
                    this.state.codeAmbassadorFormVisible
                  }
                  messageAmbassadorVisible={this.state.messageAmbassadorVisible}
                  accommodationConfigNew={this.state.accommodationConfigNew}
                  stepName={this.state.stepName}
                  accommodationOption={accommodationOption}
                  trueMessage={trueMessage}
                  errorMessage={errorMessage}
                  displayNone={this.state.displayNone}
                  stepSubmit={stepSubmit}
                  userLogged={this.props.userLogged}
                  accommodationConfig={accommodationConfig}
                  messageStepSix={this.state.messageStepSix}
                  handleChange24={this.handleChange24}
                  colorMessage={this.state.colorMessage}
                  reservationId={reservationId}
                  messageVoucher={this.state.messageVoucher}
                  Reservation={Reservation}
                  handleChange23={this.handleChange23}
                  handleSubmitVoucher={this.handleSubmitVoucher}
                  handleChange3={this.handleChange3}
                  handleSubmitAmbassador={this.handleSubmitAmbassador}
                  handleChange={handleChange}
                  participantsConfig={this.state.participantsConfig}
                  handleSubmitStepSix={this.handleSubmitStepSix}
                  handleBackToStepFive={this.handleBackToStepFive}
                  haveAVoucher={this.state.haveAVoucher}
                  ambassadorCode={this.state.ambassadorCode}
                  messageForUs={this.state.messageForUs}
                  paymentOption={this.state.paymentOption}
                  paymentType={this.state.paymentType}
                  comments={this.state.comments}
                  termsAccepted={this.state.termsAccepted}
                  paymentsWayArr={this.state.paymentsWayArr}
                  paymentsWay={this.state.paymentsWay}
                  paymentsMethod={this.state.paymentsMethod}
                  paymentsMethodArr={this.state.paymentsMethodArr}
                  voucherCode1={this.state.voucherCode1}
                  voucherCode2={this.state.voucherCode2}
                  voucherCode3={this.state.voucherCode3}
                  voucherCode4={this.state.voucherCode4}
                  voucherCode5={this.state.voucherCode5}
                  voucherCode6={this.state.voucherCode6}
                  voucherCode7={this.state.voucherCode7}
                  voucherCode8={this.state.voucherCode8}
                  voucherCode9={this.state.voucherCode9}
                  voucherCode10={this.state.voucherCode10}
                  voucherCode11={this.state.voucherCode11}
                  voucherCode12={this.state.voucherCode12}
                  messageVoucher1={this.state.messageVoucher1}
                  colorMessage1={this.state.colorMessage1}
                  messageVoucher2={this.state.messageVoucher2}
                  colorMessage2={this.state.colorMessage2}
                  messageVoucher3={this.state.messageVoucher3}
                  colorMessage3={this.state.colorMessage3}
                  messageVoucher4={this.state.messageVoucher4}
                  colorMessage4={this.state.colorMessage4}
                  messageVoucher5={this.state.messageVoucher5}
                  colorMessage5={this.state.colorMessage5}
                  messageVoucher6={this.state.messageVoucher6}
                  colorMessage6={this.state.colorMessage6}
                  messageVoucher8={this.state.messageVoucher8}
                  colorMessage8={this.state.colorMessage8}
                  messageVoucher9={this.state.messageVoucher9}
                  colorMessage9={this.state.colorMessage9}
                  handleCreateGroupCode={handleCreateGroupCode}
                  messageVoucher10={this.state.messageVoucher10}
                  colorMessage10={this.state.colorMessage10}
                  messageVoucher11={this.state.messageVoucher11}
                  colorMessage11={this.state.colorMessage11}
                  messageVoucher12={this.state.messageVoucher12}
                  colorMessage12={this.state.colorMessage12}
                  messageVoucher7={this.state.messageVoucher7}
                  colorMessage7={this.state.colorMessage7}
                  colorMessageAmb={this.state.colorMessageAmb}
                  messageAmbassador={this.state.messageAmbassador}
                  handleUpdateStepSix={this.handleUpdateStepSix}
                  secondTrueMessage={secondTrueMessage}
                  errorMessage2={errorMessage2}
                  groupRebateAvailable={groupRebateAvailable}
                  groupCode={groupCode}
                  handleAddGroupCode={this.handleAddGroupCode}
                  groupCodeCreate={groupCodeCreate}
                  render={this.state.render}
                />
              </Route>
            )}

            {this.state.stepSubmit ? (
              <Redirect to="/rezerwacja/podziekowanie" />
            ) : null}
            {this.state.stepSubmit && (
              <Route path="/rezerwacja/podziekowanie">
                <StepSubmit {...this.props} Reservation={Reservation} />
              </Route>
            )}

            {this.state.redirectToPayU
              ? window.location.replace(
                  `${APIbackend}/${this.state.paymentLink}`
                )
              : null}
          </Switch>
        </Router>
        <>
          {this.props.reservation ? (
            <>
              <ReservationMenu nameTrip={this.state.nameTrip} />
              {this.props.stepsNumber ? (
                <StepsNumber
                  stepOne={stepOne}
                  stepTwo={stepTwo}
                  stepThree={stepThree}
                  stepFour={stepFour}
                  stepFive={stepFive}
                  stepSix={stepSix}
                  stepSubmit={stepSubmit}
                  stepNumberOne={stepNumberOne}
                  stepNumberTwo={stepNumberTwo}
                  stepNumberSix={stepNumberSix}
                  stepNumberThree={stepNumberThree}
                  stepNumberFour={stepNumberFour}
                  stepNumberFive={stepNumberFive}
                />
              ) : null}

              {/* {this.props.buttons ?
                    <> <input type="text" name="offerId" value={offerId} onChange={handleChange} style={{ display: "flex", width: "200px", margin: "auto" }} placeholder="offerId"></input>
                        <button style={{ margin: "auto", display: "flex", justifyContent: "center", marginTop: "10px" }} className="primaryBtn" onClick={handleSubmitStep0}>Step 0 - create reservation</button>

                        <input type="text" name="reservationId" value={reservationId} onChange={handleChange} style={{ display: "flex", width: "200px", margin: "auto", marginTop: "20px" }} placeholder="reservationId"></input>
                        <button style={{ margin: "auto", display: "flex", justifyContent: "center", marginTop: "10px" }} className="primaryBtn" onClick={handleGetStepOne}>Step 1 - get details</button></> : null} */}

              {this.state.login ? (
                <Login
                  email={this.props.email}
                  password={this.props.password}
                  incorrectLoginMessage={this.props.incorrectLoginMessage}
                  emailIncorrect={this.props.emailIncorrect}
                  passwordIncorrect={this.props.passwordIncorrect}
                  loginActive={this.props.loginActive}
                  mainPage={this.props.mainPage}
                  type={this.props.type}
                  error={this.props.error}
                  handleChange={this.props.handleChange}
                  handleClick={this.props.handleClick}
                  handleLoginClick={this.props.handleLoginClick}
                />
              ) : null}
              {/* <Router>
                <Switch> */}
              {stepOne ? (
                <StepOne
                  infoReserveList={this.state.infoReserveList}
                  beSkipper={this.state.beSkipper}
                  buttonAvailableStepOne={this.state.buttonAvailableStepOne}
                  trueMessageVisible={this.state.trueMessageVisible}
                  buttonGenerateCodeVisible={
                    this.state.buttonGenerateCodeVisible
                  }
                  addGrupCodeVisible={this.state.addGrupCodeVisible}
                  displayNone={this.state.displayNone}
                  userLogged={this.props.userLogged}
                  handleChangeParticipants={this.handleChangeParticipants}
                  skipper={this.state.skipper}
                  handleClickMore1={this.handleClickMore1}
                  handleClickLess1={this.handleClickLess1}
                  participantsCountWhole={this.state.participantsCountWhole}
                  reservationId={reservationId}
                  showCreateCode={showCreateCode}
                  Reservation={Reservation}
                  messageStepOne={this.state.messageStepOne}
                  termsAccepted={termsAccepted}
                  trueMessage={trueMessage}
                  errorMessage={errorMessage}
                  secondTrueMessage={secondTrueMessage}
                  errorMessage2={errorMessage2}
                  handleClickLess={handleClickLess}
                  accommodationOption={accommodationOption}
                  participantsCount={participantsCount}
                  groupRebateAvailable={groupRebateAvailable}
                  handleAddGroupCode={handleAddGroupCode}
                  groupCode={groupCode}
                  handleCreateGroupCode={handleCreateGroupCode}
                  render={this.state.render}
                  groupCodeCreate={groupCodeCreate}
                  handleClickMore={handleClickMore}
                  handleChange={handleChange}
                  handleGetStepOne={handleGetStepOne}
                  handleUpdateStepOne={handleUpdateStepOne}
                  handleChange3={handleChange3}
                />
              ) : null}

              {stepTwo ? (
                <StepTwo
                  reserveListJourneyOption={this.state.reserveListJourneyOption}
                  infoReserveList={this.state.infoReserveList}
                  handleChangeAddTransport={this.handleChangeAddTransport}
                  colorJourneyPoinId={this.state.colorJourneyPoinId}
                  messageStepTwoJourneyPointId={
                    this.state.messageStepTwoJourneyPointId
                  }
                  messageStepTwoJourneyPointId2={
                    this.state.messageStepTwoJourneyPointId2
                  }
                  buttonAvailableStepTwo={this.state.buttonAvailableStepTwo}
                  handleChangePointId={this.handleChangePointId}
                  handleEditNameWhole={this.handleEditNameWhole}
                  handleEditNameSingle={this.handleEditNameSingle}
                  handleEditNameSingleReservation={
                    this.handleEditNameSingleReservation
                  }
                  handleEditNameSingleReservation2={
                    this.handleEditNameSingleReservation2
                  }
                  handleEditNameWholeReservation={
                    this.handleEditNameWholeReservation
                  }
                  handleEditNameWholeReservation2={
                    this.handleEditNameWholeReservation2
                  }
                  buttonGrey={this.state.buttonGrey}
                  messageStepTwoCabin={this.state.messageStepTwoCabin}
                  accommodationConfigNew={this.state.accommodationConfigNew}
                  colorRoom={this.state.colorRoom}
                  colorJourney={this.state.colorJourney}
                  messageStepTwoRoom2={this.state.messageStepTwoRoom2}
                  stepName={this.state.stepName}
                  handleChangeJourneyWholeYacht={
                    this.handleChangeJourneyWholeYacht
                  }
                  messageStepTwoJourney2={this.state.messageStepTwoJourney2}
                  handleChangeParticipantWholeYacht={
                    this.handleChangeParticipantWholeYacht
                  }
                  journeyId={this.state.journeyId}
                  messageStepTwoJourney={this.state.messageStepTwoJourney}
                  messageStepTwoRoom={this.state.messageStepTwoRoom}
                  messageStepTwo={this.state.messageStepTwo}
                  reservationId={reservationId}
                  Reservation={Reservation}
                  handleAddName={handleAddName}
                  inputAddNameActive={inputAddNameActive}
                  handleSaveName={handleSaveName}
                  handleEditName1={this.handleEditName1}
                  handleEditName2={this.handleEditName2}
                  handleChange={handleChange}
                  handleEditName={handleEditName}
                  yachtClass={yachtClass}
                  roomId={roomId}
                  addOptionsArr={addOptionsArr}
                  addOptions={addOptions}
                  transport={transport}
                  sameAsTheFirst={sameAsTheFirst}
                  handleChange27={this.handleChange27}
                  participantsCount={participantsCount}
                  message={this.state.message}
                  skipper={this.state.skipper}
                  handleChange14={this.handleChange14}
                  accommodationOption={accommodationOption}
                  handleUpdateStepTwoSingleReservation={
                    handleUpdateStepTwoSingleReservation
                  }
                  handleBackToStepOne={handleBackToStepOne}
                  handleCheckboxChange={handleCheckboxChange}
                  accommodationConfig={accommodationConfig}
                  handleUpdateStepTwoWholeYacht={handleUpdateStepTwoWholeYacht}
                  journeyPointId={this.state.journeyPointId}
                  handleChange4={handleChange4}
                  handleChange5={handleChange5}
                  handleChange6={handleChange6}
                  participantsCountWhole={this.state.participantsCountWhole}
                />
              ) : null}

              {stepThree ? (
                <StepThree
                  buttonAvailableStepThree={this.state.buttonAvailableStepThree}
                  stepName={this.state.stepName}
                  accommodationOption={accommodationOption}
                  handleEditNameSingleReservation={
                    this.handleEditNameSingleReservation
                  }
                  handleEditNameSingleReservation2={
                    this.handleEditNameSingleReservation2
                  }
                  handleEditNameWholeReservation={
                    this.handleEditNameWholeReservation
                  }
                  handleEditNameWholeReservation2={
                    this.handleEditNameWholeReservation2
                  }
                  accommodationConfigNew={this.state.accommodationConfigNew}
                  reservationId={reservationId}
                  messageStepThree={this.state.messageStepThree}
                  insurance={this.state.insurance}
                  sameAsTheFirst={sameAsTheFirst}
                  handleUpdateStepThree={this.handleUpdateStepThree}
                  handlebackToStepTwo={this.handlebackToStepTwo}
                  Reservation={Reservation}
                  insuranceConfig={this.state.insuranceConfig}
                  handleChange7={this.handleChange7}
                  handleChange8={this.handleChange8}
                  handleChange9={this.handleChange9}
                  handleAssistanceChange={this.handleAssistanceChange}
                  accommodationConfig={accommodationConfig}
                />
              ) : null}

              {stepFour ? (
                <StepFour
                  messageSizeAddon={this.state.messageSizeAddon}
                  buttonAvailableStepFour={this.state.buttonAvailableStepFour}
                  accommodationOption={accommodationOption}
                  accommodationConfigNew={this.state.accommodationConfigNew}
                  stepName={this.state.stepName}
                  insuranceConfig={this.state.insuranceConfig}
                  reservationId={reservationId}
                  accommodationConfig={accommodationConfig}
                  messageStepFour={this.state.messageStepFour}
                  Reservation={Reservation}
                  handleChange10={this.handleChange10}
                  handleChange11={this.handleChange11}
                  handleChange12={this.handleChange12}
                  handleChange13={this.handleChange13}
                  handleUpdateStepFour={this.handleUpdateStepFour}
                  handleGoToStepFive={this.handleGoToStepFive}
                  handleBackToStepThree={this.handleBackToStepThree}
                  addonsConfig={this.state.addonsConfig}
                  trainingsConfig={this.state.trainingsConfig}
                />
              ) : null}

              {stepFive ? (
                <StepFive
                  buttonAvailableStepFive={this.state.buttonAvailableStepFive}
                  accommodationOption={accommodationOption}
                  accommodationConfigNew={this.state.accommodationConfigNew}
                  stepName={this.state.stepName}
                  messageKR={this.state.messageKR}
                  insuranceConfig={this.state.insuranceConfig}
                  skipper={this.state.skipper}
                  countryList={this.state.countryList}
                  accommodationConfig={accommodationConfig}
                  handleValidationDate={this.handleValidationDate}
                  handleChange36={this.handleChange36}
                  handleClick={this.props.handleClick}
                  type={this.props.type}
                  colorInputRedAgree={this.state.colorInputRedAgree}
                  handleChange30={this.handleChange30}
                  handleChange15={this.handleChange15}
                  handleChange16={this.handleChange16}
                  handleChange17={this.handleChange17}
                  handleChange18={this.handleChange18}
                  handleChange19={this.handleChange19}
                  handleChange20={this.handleChange20}
                  handleChange21={this.handleChange21}
                  handleChange25={this.handleChange25}
                  handleChange22={this.handleChange22}
                  reservationId={reservationId}
                  Reservation={Reservation}
                  handleChange={handleChange}
                  messageStepFive={this.state.messageStepFive}
                  handleUpdateStepFive={this.handleUpdateStepFive}
                  handleBackToStepFour={this.handleBackToStepFour}
                  participantDetails={this.state.participantDetails}
                  detailsLater={this.state.detailsLater}
                  handleChange2={this.handleChange2}
                  list={this.state.list}
                  allChecked={this.state.allChecked}
                  colorInputRedPolicyAcc={colorInputRedPolicyAcc}
                  participantsConfig={this.state.participantsConfig}
                  message={this.state.message}
                  colorInputRedCountry={colorInputRedCountry}
                  colorInputRedPassword={colorInputRedPassword}
                  handleChange31={this.handleChange31}
                  colorInputRedPhone={colorInputRedPhone}
                  colorInputRedGender={colorInputRedGender}
                  colorInputRedFirstName={colorInputRedFirstName}
                  colorInputRedLastName={colorInputRedLastName}
                  colorInputRedBirthDate={colorInputRedBirthDate}
                  colorInputRedIdDocument={colorInputRedIdDocument}
                  colorInputRedEmail={colorInputRedEmail}
                />
              ) : null}

              {stepSix ? (
                <StepSix
                  handleSubmitAmbassadorSkipper={
                    this.handleSubmitAmbassadorSkipper
                  }
                  beSkipper={this.state.beSkipper}
                  buttonAvailableStepSix={this.state.buttonAvailableStepSix}
                  buttonGenerateCodeVisible={
                    this.state.buttonGenerateCodeVisible
                  }
                  trueMessageVisible={this.state.trueMessageVisible}
                  addGrupCodeVisible={this.state.addGrupCodeVisible}
                  codeAmbassadorFormVisible={
                    this.state.codeAmbassadorFormVisible
                  }
                  messageAmbassadorVisible={this.state.messageAmbassadorVisible}
                  stepName={this.state.stepName}
                  accommodationOption={accommodationOption}
                  accommodationConfigNew={this.state.accommodationConfigNew}
                  trueMessage={trueMessage}
                  errorMessage={errorMessage}
                  displayNone={this.state.displayNone}
                  stepSubmit={stepSubmit}
                  userLogged={this.props.userLogged}
                  accommodationConfig={accommodationConfig}
                  messageStepSix={this.state.messageStepSix}
                  handleChange24={this.handleChange24}
                  colorMessage={this.state.colorMessage}
                  reservationId={reservationId}
                  messageVoucher={this.state.messageVoucher}
                  Reservation={Reservation}
                  handleChange23={this.handleChange23}
                  handleSubmitVoucher={this.handleSubmitVoucher}
                  handleChange3={this.handleChange3}
                  handleSubmitAmbassador={this.handleSubmitAmbassador}
                  handleChange={handleChange}
                  participantsConfig={this.state.participantsConfig}
                  handleSubmitStepSix={this.handleSubmitStepSix}
                  handleBackToStepFive={this.handleBackToStepFive}
                  haveAVoucher={this.state.haveAVoucher}
                  ambassadorCode={this.state.ambassadorCode}
                  messageForUs={this.state.messageForUs}
                  paymentOption={this.state.paymentOption}
                  paymentType={this.state.paymentType}
                  comments={this.state.comments}
                  termsAccepted={this.state.termsAccepted}
                  paymentsWayArr={this.state.paymentsWayArr}
                  paymentsWay={this.state.paymentsWay}
                  paymentsMethod={this.state.paymentsMethod}
                  paymentsMethodArr={this.state.paymentsMethodArr}
                  voucherCode1={this.state.voucherCode1}
                  voucherCode2={this.state.voucherCode2}
                  voucherCode3={this.state.voucherCode3}
                  voucherCode4={this.state.voucherCode4}
                  voucherCode5={this.state.voucherCode5}
                  voucherCode6={this.state.voucherCode6}
                  voucherCode7={this.state.voucherCode7}
                  voucherCode8={this.state.voucherCode8}
                  voucherCode9={this.state.voucherCode9}
                  voucherCode10={this.state.voucherCode10}
                  voucherCode11={this.state.voucherCode11}
                  voucherCode12={this.state.voucherCode12}
                  messageVoucher1={this.state.messageVoucher1}
                  colorMessage1={this.state.colorMessage1}
                  messageVoucher2={this.state.messageVoucher2}
                  colorMessage2={this.state.colorMessage2}
                  messageVoucher3={this.state.messageVoucher3}
                  colorMessage3={this.state.colorMessage3}
                  messageVoucher4={this.state.messageVoucher4}
                  colorMessage4={this.state.colorMessage4}
                  messageVoucher5={this.state.messageVoucher5}
                  colorMessage5={this.state.colorMessage5}
                  messageVoucher6={this.state.messageVoucher6}
                  colorMessage6={this.state.colorMessage6}
                  messageVoucher8={this.state.messageVoucher8}
                  colorMessage8={this.state.colorMessage8}
                  messageVoucher9={this.state.messageVoucher9}
                  colorMessage9={this.state.colorMessage9}
                  handleCreateGroupCode={handleCreateGroupCode}
                  messageVoucher10={this.state.messageVoucher10}
                  colorMessage10={this.state.colorMessage10}
                  messageVoucher11={this.state.messageVoucher11}
                  colorMessage11={this.state.colorMessage11}
                  messageVoucher12={this.state.messageVoucher12}
                  colorMessage12={this.state.colorMessage12}
                  messageVoucher7={this.state.messageVoucher7}
                  colorMessage7={this.state.colorMessage7}
                  colorMessageAmb={this.state.colorMessageAmb}
                  messageAmbassador={this.state.messageAmbassador}
                  handleUpdateStepSix={this.handleUpdateStepSix}
                  secondTrueMessage={secondTrueMessage}
                  errorMessage2={errorMessage2}
                  groupRebateAvailable={groupRebateAvailable}
                  groupCode={groupCode}
                  handleAddGroupCode={this.handleAddGroupCode}
                  groupCodeCreate={groupCodeCreate}
                  render={this.state.render}
                />
              ) : null}

              {this.state.stepSubmit ? (
                <StepSubmit Reservation={Reservation} />
              ) : null}
            </>
          ) : null}

          {/*{this.state.Reservation.Offer ? <TripBox2 image={this.state.Reservation.Offer.ListImg}*/}
          {/*                     header={this.state.Reservation.Offer.Name}*/}
          {/*                     dateFrom={this.state.Reservation.Offer.From}*/}
          {/*                     dateTo={this.state.Reservation.Offer.To}*/}
          {/*                     totalPrice={this.state.totalPrice}*/}

          {/*/> : null}*/}
        </>
      </>
    );
  }
}

export default ReservationTrip;
